.departments_body {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 80vh;
    width: 100%;
    padding: 0 35px;
    padding-left: 7%;
    padding-right: 7%;
    position: relative;
    background-size: cover;
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-position: center;
}

.departments_body::after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.75);
    /* Semi-transparent black overlay */
    z-index: 0;
    /* Ensures the overlay is above the background image */
}


.departments_body .sec-title {
    color: #fff !important;
    margin-bottom: 30px;
}


.departments_body .wrapper {
    max-width: 100%;
    width: 100%;
    position: relative;
    z-index: 1;
}

.departments_body .wrapper i {
    height: 50px;
    width: 50px;
    background: #fff;
    text-align: center;
    line-height: 50px;
    border-radius: 50%;
    cursor: pointer;
    position: absolute;
    top: 70%;
    font-size: 1.25 rem;
    transform: translateY(-50%);
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.23);
    align-items: center;

}

.departments_body.wrapper i:first-child {
    left: -22px;

}


.departments_body .wrapper i:last-child {
    right: -22px;

}


.departments_body .wrapper .carousel {
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: calc((100% / 4) - 12px);
    gap: 16px;
    overflow-x: auto;
    scroll-snap-type: x mandatory;
    scroll-behavior: smooth;
    scrollbar-width: 0;
    align-items: center;
}


.departments_body::-webkit-scrollbar {
    display: none;
}


.departments_body :where(.card, .img) {
    display: flex;
    align-items: center;
    justify-content: center;
}


.departments_body .carousel.dragging {
    scroll-snap-type: none;
    scroll-behavior: auto;
}


.departments_body .carousel.no-transition {
    scroll-behavior: auto;
}


.departments_body .carousel.dragging .card {
    cursor: grab;
    user-select: none;
}


.departments_body .card {
    scroll-snap-align: start;
    height: 340px;
    list-style: none;
    background: #fff;
    border-radius: 8px;
    display: flex;
    cursor: pointer;
    width: 98%;
    padding-bottom: 15px;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.departments_body .card .img {
    background: #598abf;
    width: 205px;
    height: 205px;
    border-radius: 50%;

}

.departments_body .card .img img {
    width: 200px;
    height: 200px;
    object-fit: cover;
    border-radius: 50%;
    border: 4px solid #fff;
}

.departments_body .card h2 {
    font-size: 16px;
    font-weight: 600;
    margin: 30px 0 5px;
}

.departments_body.card span {
    color: #6a6d78;
    font-size: 15px;

}


.contact .section .container .row .full .accordion .bs-example .panel-group .panel .accordion-item .panel-body ul {
    list-style-type: disc;
}

/* responsive */

@media screen and (max-width: 900px) {
    .wrapper .carousel {
        grid-auto-columns: calc((100% / 2) - 9px);

    }
}

@media screen and (max-width: 600px) {
    .wrapper .carousel {
        grid-auto-columns: 100%;

    }
}