/* Notification Boxes Styles Starts */

.notification-boxes {
    display: flex; /* Allow box to be easily aligned later*/
    flex-wrap: nowrap; 
    overflow-x: auto; /* Allows horizontal scrolling if necessary */
}

.notification-boxes .box {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%; /* Ensure the box takes up the full height of its container */
    padding: 20px; /* Optional: adjust the padding as needed */
    box-sizing: border-box; /* Ensures padding is included in the height */
    margin: 30px;
    padding: 30px;
    color: #000;
    text-align: center;
    background-color: #fff;
    height: 320px;
    border: 1px solid #ddd;
    box-shadow: 1px 10px 20px rgb(42 58 70 / 20%);
}

.box .btn {
    margin-top: auto; /* Pushes the button to the bottom */
}

.notification-boxes .box h4 {
    text-align: left;
    margin-top: 16px;
    margin-bottom: 10px;
    letter-spacing: 0.5px;
}

.notification-boxes .box .icon {
    position: absolute;
    height: 60px;
    width: 60px;
    border: 2px dashed #ddd;
    background-color: #fff;
    top: -20px;
    left: 30px;
    z-index: 9999;
}

.notification-boxes .box i {
    color: #598abf;
    line-height: 56px;
    font-size: 25px;
}

.notification-boxes .box p {
    text-align: left;
}

/* Lastly change the position of the button for consistency */
.notification-boxes .box .btn-transparent {
    position: absolute; /* Position the button absolutely within the box */
    bottom: 1rem; /* Distance from the bottom of the box */
    left: 4rem; /* Optional: distance from the left of the box */
    right: 4rem; /* Optional: distance from the right of the box, or you can set `left` and `right` to `0` */
    margin-bottom: 10px;
    font-weight: bold;
}

.notification-boxes .box .btn-transparent {
    /* background: linear-gradient(45deg, #b0c4de, #598abf) !important; */
    border-color: #598abf;
    background-color: #fff;
    color: #598abf;
    margin-top: 10px;
}

.notification-boxes .box .btn-transparent:hover,
.notification-boxes .box .btn-transparent:focus,
.notification-boxes .box .btn-transparent:active,
.notification-boxes .box .btn-transparent.active {
    color: #fff;
    background-color: #598abf;
}


/*====Box hover border=======*/

.hover-border-outer {
    position: relative;
    z-index: 1;
    display: block;
}

.hover-border-outer::before,
.hover-border-outer::after {
    box-sizing: inherit;
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: -1;
    display: black;
}

.hover-border {
    transition: color 0.25s;
}

.hover-border::before,
.hover-border::after {
    border: 2px solid transparent;
    width: 0;
    height: 0;
}

.hover-border::before {
    top: 0;
    left: 0;
}

.hover-border::after {
    bottom: 0;
    right: 0;
}

.hover-border:hover::before,
.hover-border:hover::after {
    width: 100%;
    height: 100%;
    display: block;
}

.hover-border:hover::before {
    border-top-color: #598abf;
    border-right-color: #598abf;
    transition: width 0.25s ease-out, height 0.25s ease-out 0.25s;
}

.hover-border:hover::after {
    border-bottom-color: #598abf;
    border-left-color: #598abf;
    transition: border-color 0s ease-out 0.5s, width 0.25s ease-out 0.5s, height 0.25s ease-out 0.75s;
}



/* Main Container Styles Starts */
.main_media {
    background-color: #f1f1f1;
    width: 100%;
    padding-top: 1px;
}

.main-container {
    margin-top: 30px;
}

.main-container.no-margin-top {
    margin-top: 0;
}