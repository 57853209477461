/* Main Banner Styles Starts */

.main-banner {
  padding-top: 130px;
  padding-bottom: 70px;
  height: 18rem;
  background-size: cover !important;
}

.main-banner h2 {
  margin-top: 0;
  margin-bottom: 0;
  color: #000;
}

.main-banner h2 span {
  padding: 14px 50px 16px 30px;
  letter-spacing: 0.3px;
  background: rgba(255, 255, 255, 0.8);
  border-radius: 28px 0;
}

/* Breadcrumb Styles Starts */

.breadcrumb {
  margin-bottom: 0;
  padding: 5px 0 5px;
  background-color: #f1f1f1;
}

.breadcrumb ul {
  margin-bottom: 0;
}

.breadcrumb li+li::before {
  content: "\f30b";
  padding-right: 10px;
  color: #676767;
}

.breadcrumb li a {
  color: #676767;
}

.breadcrumb li a:hover,
.breadcrumb li a:focus,
.breadcrumb li.active {
  color: var(--thm-primary);
}

.hero-department h1 {
  font-size: 2.5em;
  font-weight: 600;
}

.department-container {
  position: relative;
  width: 80%;
  margin-left: 10%;
  margin-right: 10%;
  margin-top: 40px;
  margin-bottom: 40px;
  background-color: #fff;
  overflow: hidden;
  border-radius: 10px;
  box-shadow: 1px 20px 30px rgb(42 68 90 / 30%);
  z-index: 1;
}

.department-container ul li p {
  padding: 0.3em;
}

.department-container ul li::before {
  font-size: 20pt;
  vertical-align: middle;
  color: #000;
  margin-right: 0.5em;
}

.department-container .department-about {
  padding-top: 40px;
  padding-bottom: 40px;
  padding-left: 50px;
  padding-right: 10px;
  padding: 2em 2em;
}

.department-container .department-about ul li {
  margin-left: 15px;
}

.department-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;

}

@media screen and (max-width: 900px) {
  .department .container {
    grid-auto-columns: calc((100% / 1) - 9px);

  }
}

@media screen and (max-width: 600px) {
  .department .container {
    grid-auto-columns: 100%;

  }
}