/*--------------------------------------------------------------------------------------------
          services overlay
-----------------------------------------------------------------------------------------------*/

.benifit {
  width: 85%;
  position: relative;
  margin-top: -250px;
  background: white;
  margin-left: auto;
  margin-right: auto;
  padding: 1em;
  border-radius: 5px;
  box-shadow: 1px 10px 20px rgb(42 68 90 / 30%);
  object-fit: contain;
  z-index: 6;
}

.benifit__serve_text {
  object-fit: contain;
  text-decoration: solid;
  text-align: center;
  vertical-align: middle;
  line-height: 50px;
}

.benifit__item_first {
  object-fit: contain;
  text-align: center;
  color: #598abf;
  background-color: ffffff;
  padding: 0.5em 0.5em 0.5em 0.5em;
  border: 0.2em solid #598abf;
  border-radius: 5px;
}

.benifit__item_second {
  text-align: center;
  color: #d2b356;
  background: #ffffff;
  padding: 0.5em 0.5em 0.5em 0.5em;
  border: 0.2em solid #d2b356;
  border-radius: 5px;
  object-fit: contain;
}

.benifit__item_last {
  object-fit: contain;
  text-align: center;
  color: #598abf;
  background-color: ffffff;
  padding: 0.5em 0.5em 0.5em 0.5em;
  border: 0.2em solid #598abf;
  border-radius: 5px;
}

.benifit__item_first:hover {
  color: #ffffff;
  background:  #598abf;
  border: 0.2em solid #598abf;
}

.benifit__item_second:hover {
  color: #ffffff;
  background: #d2b356;
  border: 0.2em solid #d2b356;
}

.benifit__item_last:hover {
  color: #ffffff;
  background:  #598abf;
  border: 0.2em solid #598abf;
}

.benifit__item_first i, .benifit__item_second i, .benifit__item_last i {
  font-size: 3em;
  font-weight: 550;
  padding: 15px 15px 15px
}

.benifit__item_first .benifit__item_second .benifit__item_last h4 {
  color: #ffffff;
  font-weight: 600;
  padding: 15px 15px 15px
}

.benifit__item_first .service_text, .benifit__item_second .service_text, .benifit__item_last .service_text  {
  font-size: 15px;
  font-weight: 400;
  padding: 0.5em 0.5em 0.5em;
}


/* Extra Small Devices */
@media (max-width: 576px) {

  /* Styles for very small screens */
  .benifit {
    margin-top: -190px;
  }
}

/* Small Devices */
@media (min-width: 577px) and (max-width: 768px) {
  /* Styles for small devices */
}

/* Medium Devices */
@media (min-width: 769px) and (max-width: 992px) {
  /* Styles for medium devices */
}

/* Large Devices */
@media (min-width: 993px) and (max-width: 1200px) {
  /* Styles for large devices */
}

/* Extra Large Devices */
@media (min-width: 1201px) {
  /* Styles for extra large devices */
}