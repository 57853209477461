/* Header Styles Starts */

.main-header {
    background-color: #fff;
}


/* Sticky Header Styles */
.stricky.stricky-fixed {
    background: #fff;
    width: 100%;
}

.stricky.stricky-fixed {
    position: fixed;
    top: 0;
    z-index: 999;
    box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.3);
}

/* Top Bar Styles Starts */

.top-bar {
    padding: 10px 3px;
    background-color: #598abf;
    box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.3);
}

.top-bar,
.top-bar a {
    color: #fff;
}

.top-bar span {
    color: #fff;
}

.top-bar ul {
    text-align: right;
    margin-bottom: 0;
}

.top-bar li {
    margin-right: 0 !important;
    padding-left: 6px;
    padding-right: 6px;
    border-right: 1px solid #d3d3d3;
}

.top-bar li:last-of-type {
    padding-right: 0;
    border: none;
}

.top-bar li .fa {
    margin-right: 5px;
    vertical-align: middle;
}

.top-bar li a:hover {
    color: #ddd;
}

.top-bar li .btn-group {
    vertical-align: top;
}

.top-bar li .btn-group>.btn {
    padding: 0;
    font-size: 14px;
    background: none;
}

.top-bar li .btn-group>.btn-link {
    color: #3b3a3a;
    text-decoration: none;
}

.top-bar li .btn-group>.btn-link i.fa {
    color: #d2b356;
    ;
}

.top-bar li .btn-group.open .btn-link,
.top-bar li .btn-group.open .btn-link i.fa {
    color: #d2b356;
    ;
}

.top-bar li .btn-group>.btn-link i.fa {
    margin-top: -3px;
    margin-left: 5px;
}

.top-bar li .btn-group .dropdown-menu li {
    padding: 0;
}

.alert-success {
    color: #fff;
    background-color: #68a554;
    border-color: #599b43;
    border-radius: 0;
}

.alert-error {
    color: #fff;
    background-color: #f95b5b;
    border-color: #f74c4c;
    border-radius: 0;
}

.icon-text-ml {
    position: relative;
    font-size: 20px;
    top: 3px;
    right: 5px;
    font-size: 20px;
    top: 3px;
    right: 5px;
}


/* Navbar Styles Starts */

#nav {
    margin-bottom: 0;
    padding: 0 0 0px;
    background: none;
}

#nav .navbar-brand {
    color: #d2b356;
    ;
    height: auto;
    line-height: normal;
}

#nav.navbar {
    margin-top: 0px;
}

#nav .navbar-brand img {
    width: auto;
    max-height: 70px;
}

#nav .nav-link {
    font-size: 15px;
    font-weight: 510;
    color: #313131;
    line-height: normal;
}

#nav .nav-item.active>.nav-link,
#nav .nav-item.open>.nav-link,
#nav .nav-item.open>.nav-link:hover,
#nav .nav-item.open>.nav-link:focus,
#nav .dropdown-menu>.active>.dropdown-item,
#nav .dropdown-menu>.active>.dropdown-item:hover,
#nav .dropdown-menu>.active>.dropdown-item:focus {
    background: none;
}

#nav .nav-item:hover>.nav-link,
#nav .nav-item.active>.nav-link,
#nav .nav-item>.nav-link:hover,
#nav .nav-item>.nav-link:focus {
    color: #d2b356;
}

#nav .dropdown-menu {
    background-color: #fff;
    padding: 0;
    left: 0;
    border: none;
    text-transform: capitalize;
    min-width: 230px;
    box-shadow: 2px 0 11px -3px rgba(0, 0, 0, .2) !important;
    border-top: 3px solid #262626;
    display: block;
    opacity: 0;
    visibility: hidden;
    transition: all 0.5s ease-out 0s;
    top: 60px;
    transition: all 0.5s ease-out 0s;
    top: 60px;
}

#nav li.dropdown:hover>.dropdown-menu,
#nav li.dropdown .dropdown-menu:hover {
    opacity: 1;
    visibility: visible;
    top: 36px;
    top: 36px;
}

#nav .dropdown-menu>.dropdown-item {
    padding-top: 5px;
    padding-bottom: 5px;
    color: #000;
    padding: 10px 22px;
    border-bottom: 1px solid #E9E9E9;
}

#nav .dropdown-menu>.dropdown-item:last-of-type {
    border-bottom: 0;
}

#nav .dropdown-menu>.dropdown-item:hover,
#nav .dropdown-menu>.dropdown-item:focus {
    background-color: #d2b356;
    ;
    color: #fff !important;
}

#nav .dropdown-menu>.dropdown-item.active,
#nav .dropdown-menu>.dropdown-item.active:hover,
#nav .dropdown-menu>.dropdown-item.active:focus {
    background-color: var(--thm-hover);
    color: #fff !important;
}

#nav .navbar-toggler {
    margin-bottom: 0;
    outline: none;
    cursor: pointer;
    padding: 10px !important;
    border: 1px solid #ddd !important;
}

#nav .navbar-toggler:focus {
    text-decoration: none;
    outline: none;
    -webkit-box-shadow: none;
    box-shadow: none;
}

#nav .navbar-toggler span {
    color: #313131;
}

#nav .navbar-toggler:hover span {
    color: #d2b356;

}

#nav .arrow:after {
    content: "\f078";
    font-family: 'Font Awesome 5 Free';
    font-weight: 500;
    margin-left: 4px;
    display: inline;
    font-size: 11px;
    height: auto;
    text-shadow: none;
    width: 10px;
    display: inline-block;
}

/* #nav .dropdown-menu a.dropdown-item:before {
    content: "\f054";
    font-family: 'Font Awesome 5 Free';
    font-weight: 900;
    position: absolute;
    left: 12px;
    font-size: 9px;
} */

#nav .dropdown-menu a.dropdown-item:before {
    content: "\f054";
    font-family: 'Font Awesome 5 Free';
    font-weight: 900;
    display: inline-block !important;
    vertical-align: middle !important;
    left: 12px;
    margin-right: 0.5em;
    font-size: 9px;
}

@media (min-width: 992px) {
    #nav .navbar-nav>li {
        margin-left: 5px;
    }

    #nav .navbar-nav>li>a {
        background: transparent;
        position: relative;
        -webkit-transform: translateZ(0);
        transform: translateZ(0);
        padding-right: .4rem !important;
        padding-left: .4rem !important;
    }

    #nav .navbar-nav>li>a:before,
    #nav .navbar-nav>li>a:after {
        content: '';
        position: absolute;
        width: 10px;
        height: 10px;
        opacity: 0;
        border: 2px solid #d2b356;
        ;
        -webkit-transition: -webkit-transform 0.3s, opacity 0.3s;
        transition: transform 0.3s, opacity 0.3s;
        -webkit-transition-timing-function: cubic-bezier(0.17, 0.67, 0.05, 1.29);
        transition-timing-function: cubic-bezier(0.17, 0.67, 0.05, 1.29);
    }

    #nav .navbar-nav>li>a:before {
        top: 0;
        left: 0;
        border-width: 2px 0 0 2px;
        -webkit-transform: translate3d(10px, 10px, 0);
        transform: translate3d(10px, 10px, 0);
    }

    #nav .navbar-nav>li>a:after {

        right: 0;
        bottom: 0;
        border-width: 0 2px 2px 0;
        -webkit-transform: translate3d(-10px, -10px, 0);
        transform: translate3d(-10px, -10px, 0);

    }

    #nav .navbar-nav>li:hover>a:before,
    #nav .navbar-nav>li.active>a:before,
    #nav .navbar-nav>li:hover>a:after,
    #nav .navbar-nav>li.active>a:after {
        opacity: 1;
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }

    #nav .navbar-nav>li.m-login {
        display: none;
        visibility: hidden;
    }
}

@media (max-width: 992px) {
    .navbar-nav .nav-item {
        border-bottom: 1px solid #eee;
    }

    .navbar-nav .nav-item:last-of-type {
        border-bottom: 0;
    }
}



.ml-auto,
.mx-auto {
    margin-left: auto !important;
}