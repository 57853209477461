.gallery{
    margin-top:1rem;
    background: #ffff;
    overflow: hidden;
}


.gallery .gallery_grid_row{
    margin-bottom: 1em;
}

.gallery .single_box{
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    width: 100%;
    height: 300px;
    overflow: hidden;
    transition: .6s;
    margin-top: 2em;
    position: relative;
}

.gallery .single_box .img_footer{
    top: auto;
    bottom: 0;
    padding: 1em;
    height: 3.75em;
    background: #fff;
    color: #3c4a50;
    position: absolute;
    width: 100%;
    display: block;
}

.gallery .gallery_grid_row .single_box .img_footer .img_caption{
    font-size: 14px!important;
}

.gallery .single_box .img_footer .icon-links{
    float: right;
    margin-top: -1.9rem;
    color: #3c4a50; 
}

.gallery .single_box p img{
    height: 300px;
}
    
