/* Slider Area Start */
.owl-nav-prev,
.owl-nav-next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.5);
  color: #fff;
  border: none;
  padding: 10px;
  cursor: pointer;
  font-size: 24px;
}

.owl-nav-prev {
  left: 10px;
}

.owl-nav-next {
  right: 10px;
}

.main-slider {
  min-height: 15px;
}

.main-slider .container-fluid {
  padding: 0px;
}

.main-slider .c-center .container {
  text-align: left;
}

.main-slider .c-left .wrap-caption {
  width: 100%;
  height: 50%;
  margin-top: 2px;

}

.main-slider .c-right .wrap-caption {
  width: 100%;
  margin-left: 20px;
  margin-top: 2px;
}

.main-slider .slider-wrapper {
  position: relative;
  margin-bottom: 180px;
}


.main-wrapper .main-slider .container-fluid .slide .carousel-indicators {
  display: none;
}

.main-slider .slider-caption {
  position: absolute;
  left: 0;
  top: 0px;
  right: 0;
  bottom: 0;
  display: flex;
  -webkit-box-align: left;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  z-index: 5;
}

.main-slider .slider-caption .inner-box {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 30px;
}

.main-slider .slider-caption-bg {
  max-width: 600px;
  background-color: rgba(255, 255, 255, 0.7);
  padding: 50px;

}

.main-slider .slider-wrapper .image {
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top center;
  height: 550px;
  color: #fff;
}

.main-slider h2 {

  position: relative;
  text-transform: capitalize;
  color: #fff;
  font-size: 35px;
  font-weight: 700;
  line-height: 55px;
  font-family: 'Work Sans', sans-serif;
}

.main-slider h1 span {
  font-weight: 400;
  color: #598abf;
}

.main-slider .link-btn {
  padding-top: 0;
  position: relative;
}

.main-slider .link-btn .btn {
  margin-right: 10px;
  margin-top: 10px;
}

.main-slider .link-btn .btn {
  background: #598abf;
  color: #fff;
  z-index: 9;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  padding: 12px 12px;
  position: relative;
}

.main-slider .link-btn .btn1 {
  border: 1px solid #fff;
  background: transparent;
}

.main-slider .link-btn .btn:hover {
  background: #fff;
  color: #d2b356;
  border: 1px solid #fff;
}

.main-slider .theme-btn {
  margin: 0 10px 10px;
}

.main-slider .text {
  position: relative;
  font-size: 17px;
  font-weight: 400;
  color: #fff;
  line-height: 1.7em;
  padding-bottom: 20px;
}

.main-slider .c-center .text {
  max-width: 600px;
  margin: 0 auto;
}

.main-slider .slide-overlay {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.50);
  z-index: 3;
}

.main-slider.style-two .slide-overlay {
  background-color: rgba(20, 22, 26, 0.1)
}


/* slider animation */

.main-slider .owl-item .slider-caption * {
  -webkit-animation-duration: 10s;
  animation-duration: 10s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.main-slider .owl-item.active .slider-caption h1 {
  -webkit-animation-delay: .10s;
  animation-delay: .10s;
  -webkit-animation-name: fadeInLeft;
  animation-name: fadeInLeft;
}

.main-slider .owl-item.active .slider-caption .text {
  -webkit-animation-delay: .10s;
  animation-delay: .10s;
  -webkit-animation-name: fadeInRight;
  animation-name: fadeInRight;
}

.main-slider .owl-item.active .slider-caption .link-btn {
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
}

.main-slider .owl-item.active .slider-caption .slider-caption-bg {
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
}


/* Slide Nav */

.slide-nav .owl-nav {
  padding: 0px;
  top: 50%;
  -webkit-transform: translateY(-50%) scale(0.97);
  -ms-transform: translateY(-50%) scale(0.97);
  transform: translateY(-50%) scale(0.97);
  position: absolute;
  left: 0;
  right: 0;
  opacity: 0;
  -webkit-transition: .5s ease;
  -o-transition: .5s ease;
  transition: .5s ease;
}

.slide-nav:hover .owl-nav {
  -webkit-transform: translateY(-50%) scale(1);
  -ms-transform: translateY(-50%) scale(1);
  transform: translateY(-50%) scale(1);
  opacity: 1;
}

.slide-nav .owl-nav .owl-next,
.slide-nav .owl-nav .owl-prev {
  position: relative;
  width: 50px;
  height: 50px;
  line-height: 46px;
  background: #598abf !important;
  text-align: center;
  font-size: 24px;
  color: #fff !important;
  transition: all 300ms ease;
  -webkit-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
}

.slide-nav .carousel-outer:hover .owl-nav .owl-next,
.slide-nav .carousel-outer:hover .owl-nav .owl-prev {
  opacity: 1;
  visibility: visible;
}

.slide-nav .owl-nav .owl-prev {
  left: 20px;
  float: left;
}

.slide-nav .owl-nav .owl-next {
  right: 20px;
  float: right;
}

.slide-nav .owl-nav .owl-next:hover,
.slide-nav .owl-nav .owl-prev:hover {
  color: #fff;
  border-color: #598abf;
}

ul.owl-carousel {
  padding: 0;
  list-style: none;
}

.carousel-control-next,
.carousel-control-prev {

  border: 0;
  bottom: 0;
  color: #fff;
  display: flex;
  opacity: .5;
  top: 30%;
  margin-right: -5px;
  /* margin-left: -5px; */
  bottom: 5px;
  transition: opacity .15s ease;
  width: 3rem;
  height: 3rem;
  align-items: center;
  z-index: 20;
}

.carousel-control-next:hover,
.carousel-control-prev:hover {
  background: #598abf;
  border: 0;
  bottom: 0;
  color: #fff;
  display: flex;
  top: 30%;
  margin-right: -5px;
  /* margin-left: -5px; */
  bottom: 5px;
  transition: opacity .15s ease;
  width: 3rem;
  height: 3rem;
  align-items: center;
  z-index: 20;
}

.slide-nav .owl-nav .owl-next,
.slide-nav .owl-nav .owl-prev {
  position: relative;
  width: 50px;
  height: 20px;
  line-height: 12px;
  background: #598abf !important;
  text-align: center;
  color: #fff !important;
  transition: all 300ms ease;
  -webkit-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
}

/* Main Slider Area End */


/* Media Query */
/* Small Devices */
@media (min-width: 577px) and (max-width: 768px) {

  /* Styles for small devices */
  .main-slider .container-fluid {
    margin-bottom: 100px !important;
  }
}

/* Medium Devices */
@media (min-width: 769px) and (max-width: 992px) {

  /* Styles for medium devices */
  .main-slider .container-fluid {
    margin-bottom: 80px !important;
  }
}