#footer {
  background: #598abf;
  padding: 30px 0 0 0;
  width: 100%;
  font-size: 14px;
  color: #fff;
  line-height: 18px;
  bottom: 0;
  overflow: hidden;
}


#footer .iso-logo img {
  display: inline-block;
  width: 120px;
  margin: 0.5em 0.5em;
}

#footer .iso-logo {
  margin-top: 1.5em;
}


#footer h4 {
  position: relative;
  color: #fff;
  margin: 0;
  line-height: 2;
}


#footer .contact-info span {
  display: block;
  padding: .1em;
  margin: 2px 0;
}

.socials {
  text-align: center;

}

#footer .socials a {
  display: inline-block;
  width: 40px;
  height: 41px;
  margin: 10px 5px 10px;
  border: 2px solid gray;
  border-radius: 5px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.3);
  padding-top: 6px;
  text-align: center;
  font-size: 2em;
  transition: all .3s ease;
  background-size: 100% 200%;
  background-image: linear-gradient(to bottom, #007bff 50%, #ffffff 50%);
  transition: background-position .2s ease-in-out, color .2s ease-in-out;
}

#footer .socials a:hover {
  border: 2px solid gray;
  background-color: white;
  transition: all .2s ease;
  background-position: 0 100%;
  color: #fff;
  -webkit-border-radious: 100%;
  -webkit-transition: all .2s ease;
}

#map,
.fa-map-marker-alt:hover {
  content: "\f3c5";
  color: #3b5998;
}

#footer .socials a:hover #facebook {
  color: #3b5998;
}

#footer .socials a:hover #twitter {
  color: #1DA1F2;
}

#footer .socials a:hover #instagram {
  background: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%, #d6249f 60%, #285AEB 90%);
  -webkit-background-clip: text;
  /* Also define standard property for compatibility */
  background-clip: text;
  -webkit-text-fill-color: transparent;
  transition: all .3s ease;
  -webkit-border-radious: 100%;
  -webkit-transition: all .3s ease;
}

#myButton {
  position: absolute !important;
}

#footer .socials a .floating-wpp-button i {
  color: #ffffff;
}

#footer .socials a:hover .floating-wpp-button i {
  color: #128C7E;
}

#footer p {
  font-size: 14px !important;
}

#footer ul {
  padding-left: 0rem;
  list-style-type: square;
  margin-top: 1em !important;
}

#footer .address {
  margin-top: 1.5em !important;

}

#footer ul li {

  padding: 5px 0;
}

#footer ul li a {
  font-size: 15px;
  transition: all .3s ease-in-out;
  padding-left: 25px;


}



.footer-links h4,
.footer-link h4 {
  position: relative;
  color: #fff;
  margin: 0;
  line-height: 2;
}


.footer-links h4:after,
.footer-link h4:after {
  position: absolute;
  content: "";
  height: 4px;
  width: 58px;
  left: 0;
  bottom: -4px;
  background: #fff;
  border-radius: 6px;
}

#footer .quick-links a:hover {
  margin-left: 1em;
  text-decoration: none;
}

#footer h3 {
  color: #fff;
  line-height: 52px;
  padding-bottom: 20px;
  font-weight: 200;
  position: relative;
}



#footer a {
  color: #fff !important
}


#footer #map {
  width: 100%;
  height: 200px;
  margin-top: 1.5em !important;
  background: #fff
}

#footer ul li {
  list-style: none;
}

#footer ul li:before {
  font-family: 'FontAwesome';
  content: '\f105';
  font-weight: 500;
  margin: 0 5px;
  color: #FFF;
}

#footer #map a {
  padding: 10px;
  color: #007bff !important;
  font-size: 1em
}

#footer #map iframe {
  width: 100%;
  height: 250px
}

#footer .copy {
  background: #598abf;
  border-top: 2px sold #fff;
  text-align: center;
  clear: both;
  color: #d2b356;
  font-size: 18px;
  padding: 1.5em;
}

/* < !-- divider --> */
#footer hr {
  margin: 1em auto;
  height: 2px;
  width: 83%;
  background-color: #d2b356;
  border: none;
  margin-left: auto;
  margin-right: auto;
}


.useful-links {
  padding: 0;
  /* Remove default padding */
  margin: 0;
  /* Remove default margin */
  display: grid;
  /* Use CSS Grid */
  grid-template-columns: 1fr 1fr;
  /* Create two equal columns */
  gap: 0rem;
  /* Space between columns, adjust as needed */
}

.useful-links a {
  transition: all .3s ease-in-out;
  padding-left: 2px !important;
}