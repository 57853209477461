main {
    display: block;
}

hr {
    box-sizing: content-box;
    height: 0;
    overflow: visible;
}


input,
optgroup,
select,
textarea {
    font-family: inherit;
    font-size: 100%;
    padding-top: 5px;
    line-height: 1.5;
    margin: 15px;
}



textarea {
    overflow: auto;
}


input,
textarea {
    -webkit-transition: all 0.30s ease-in-out;
    -moz-transition: all 0.30s ease-in-out;
    -ms-transition: all 0.30s ease-in-out;
    -o-transition: all 0.30s ease-in-out;
    transition: all 0.30s ease-in-out;
    outline: none;
}

/*--------------------------------------------------------------
 Forms
--------------------------------------------------------------*/

label {
    display: block;
    font-weight: 700;
    margin-bottom: 0.5em;
}

fieldset {
    border: 1px solid #e0e0e0;
    margin: 0 2px 1em 2px;
    padding: 0.35em 0.625em 0.75em;
}

input[type="text"],
input[type="email"],
input[type="url"],
input[type="password"],
input[type="search"],
input[type="number"],
input[type="tel"],
input[type="range"],
input[type="date"],
input[type="month"],
input[type="week"],
input[type="time"],
input[type="datetime"],
input[type="datetime-local"],
input[type="color"],
textarea {
    color: #666;
    background: #fff;
    background-image: -webkit-linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0));
    border: 1px solid #e0e0e0;
    display: block;
    width: 100%;
    padding: 10px 12px;
}

input[type="text"]:focus,
input[type="email"]:focus,
input[type="url"]:focus,
input[type="password"]:focus,
input[type="search"]:focus,
input[type="number"]:focus,
input[type="tel"]:focus,
input[type="range"]:focus,
input[type="date"]:focus,
input[type="month"]:focus,
input[type="week"]:focus,
input[type="time"]:focus,
input[type="datetime"]:focus,
input[type="datetime-local"]:focus,
input[type="color"]:focus,
textarea:focus {
    color: #333;
    border-color: #598abf;
    transition: all 0.5s ease;
}



/* Contact Info Widget */

.widget_contact_info .contact-map {
    margin-bottom: 0.5em;
}


.site__content {
    padding: 5rem 0;
    margin-left: -5rem;
}

.contact-section.contact-section-boxed>.contact-container {
    max-width: 1140px;
}

.e-con {
    --container-max-width: 1140px;
}

.contact-widget:not(:last-child) {
    margin-block-end: 20px;
}

.contact-element {
    --widgets-spacing: 20px 20px 20px 20px;
}

h1.entry-title {
    display: #000;
}

.contact-825 .contact-element.contact-element-fd63416:not(.contact-motion-effects-element-type-background)>.contact-widget-wrap,
.contact-825 .contact-element.contact-element-fd63416>.contact-widget-wrap>.contact-motion-effects-container>.contact-motion-effects-layer {
    background-color: #ffffff;
}

.contact-825 .contact-element.contact-element-fd63416>.contact-element-populated,
.contact-825 .contact-element.contact-element-fd63416>.contact-element-populated>.contact-background-overlay,
.contact-825 .contact-element.contact-element-fd63416>.contact-background-slideshow {
    border-radius: 5px 5px 5px 5px;
}

.contact-825 .contact-element.contact-element-fd63416>.contact-element-populated {
    transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
    margin: 0px 20px 0px 20px;
    --e-column-margin-right: 20px;
    --e-column-margin-left: 0px;
    padding: 10px 40px 0px 40px;
}

.contact-825 .contact-element.contact-element-fd63416>.contact-element-populated>.contact-background-overlay {
    transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}

.contact-825 .contact-element.contact-element-22d24c1 .edubin-section-title {
    text-align: left;
}

.contact-825 .contact-element.contact-element-22d24c1 .edubin-section-title .section-title-txt {
    font-family: "Montserrat", Sans-serif;
    font-size: 48px;
    font-weight: 700;
    line-height: 58px;
    padding: 0px 0px 0px 0px;
    display: inline-block;
}

.contact-825 .contact-element.contact-element-22d24c1 .edubin-section-title .section-subtitle-txt {
    font-family: "Montserrat", Sans-serif;
    font-size: 18px;
    font-weight: 700;
    display: block;
}

.contact-825 .contact-element.contact-element-22d24c1>.contact-widget-container {
    padding: 0px 0px 10px 0px;
}

.contact-825 .contact-element.contact-element-be6e39d .edubin-form-wrapper .wpcf7 {
    padding: 0px 0px 0px 0px;
}

.contact-825 .contact-element.contact-element-be6e39d .edubin-form-wrapper {
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0);
}

.contact-825 .contact-element.contact-element-be6e39d .wpcf7-form .wpcf7-form-control-wrap input[type*="text"] {
    height: 45px;
}

.contact-825 .contact-element.contact-element-be6e39d .wpcf7-form .wpcf7-form-control-wrap input[type*="email"] {
    height: 45px;
}

.contact-825 .contact-element.contact-element-be6e39d .wpcf7-form .wpcf7-form-control-wrap input[type*="url"] {
    height: 45px;
}

.contact-825 .contact-element.contact-element-be6e39d .wpcf7-form .wpcf7-form-control-wrap input[type*="number"] {
    height: 45px;
}

.contact-825 .contact-element.contact-element-be6e39d .wpcf7-form .wpcf7-form-control-wrap input[type*="tel"] {
    height: 45px;
}

.contact-825 .contact-element.contact-element-be6e39d .wpcf7-form .wpcf7-form-control-wrap input[type*="date"] {
    height: 45px;
}

.contact-825 .contact-element.contact-element-be6e39d .wpcf7-form .wpcf7-form-control-wrap .wpcf7-select {
    height: 45px;
}

.contact-825 .contact-element.contact-element-be6e39d .wpcf7-form .wpcf7-form-control-wrap textarea {
    height: 120px;
}

.contact-825 .contact-element.contact-element-be6e39d .wpcf7-form .wpcf7-submit {
    height: 50px;
}

.contact-825 .contact-element.contact-element-9222f00:not(.contact-motion-effects-element-type-background)>.contact-widget-wrap,
.contact-825 .contact-element.contact-element-9222f00>.contact-widget-wrap>.contact-motion-effects-container>.contact-motion-effects-layer {
    background-color: #ffffff;
}

.contact-825 .contact-element.contact-element-9222f00>.contact-element-populated,
.contact-825 .contact-element.contact-element-9222f00>.contact-element-populated>.contact-background-overlay,
.contact-825 .contact-element.contact-element-9222f00>.contact-background-slideshow {
    border-radius: 5px 5px 5px 5px;
}

.contact-825 .contact-element.contact-element-9222f00>.contact-element-populated {
    transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
    margin: 0px 20px 0px 20px;
    --e-column-margin-right: 0px;
    --e-column-margin-left: 20px;
    padding: 10px 40px 0px 40px;
}


.contact-825 .contact-element.contact-element-9222f00>.contact-element-populated>.contact-background-overlay {
    transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}

.contact-825 .contact-element.contact-element-4b2aeb3 .edubin-section-title {
    text-align: left;
}

.contact-825 .contact-element.contact-element-4b2aeb3 .edubin-section-title .section-title-txt {
    color: #598abf;
    font-family: "Montserrat", Sans-serif;
    font-size: 32px;
    font-weight: 700;
    line-height: 58px;
    padding: 0px 0px 0px 0px;
    display: inline-block;
}

.contact-825 .contact-element.contact-element-8b8f54b .contact-icon-list-items:not(.contact-inline-items) .contact-icon-list-item:not(:last-child) {
    padding-bottom: calc(25px/2);
}

.contact-825 .contact-element.contact-element-8b8f54b .contact-icon-list-items:not(.contact-inline-items) .contact-icon-list-item:not(:first-child) {
    margin-top: calc(25px/2);
}

.contact-825 .contact-element.contact-element-8b8f54b .contact-icon-list-items.contact-inline-items .contact-icon-list-item {
    margin-right: calc(25px/2);
    margin-left: calc(25px/2);
}

.contact-825 .contact-element.contact-element-8b8f54b .contact-icon-list-items.contact-inline-items {
    margin-right: calc(-25px/2);
    margin-left: calc(-25px/2);
}

body.rtl .contact-825 .contact-element.contact-element-8b8f54b .contact-icon-list-items.contact-inline-items .contact-icon-list-item:after {
    left: calc(-25px/2);
}

body:not(.rtl) .contact-825 .contact-element.contact-element-8b8f54b .contact-icon-list-items.contact-inline-items .contact-icon-list-item:after {
    right: calc(-25px/2);
}

.contact-825 .contact-element.contact-element-8b8f54b .contact-icon-list-item:not(:last-child):after {
    content: "";
    border-color: #eeeeee;
}

.contact-825 .contact-element.contact-element-8b8f54b .contact-icon-list-items:not(.contact-inline-items) .contact-icon-list-item:not(:last-child):after {
    border-top-style: solid;
    border-top-width: 1px;
}

.contact-825 .contact-element.contact-element-8b8f54b .contact-icon-list-items.contact-inline-items .contact-icon-list-item:not(:last-child):after {
    border-left-style: solid;
}

.contact-825 .contact-element.contact-element-8b8f54b .contact-inline-items .contact-icon-list-item:not(:last-child):after {
    border-left-width: 1px;
}

.contact-825 .contact-element.contact-element-8b8f54b .contact-icon-list-icon i {
    color: #598abf;
    transition: color 0.3s;
}

.contact-825 .contact-element.contact-element-8b8f54b .contact-icon-list-icon svg {
    fill: #598abf;
    transition: fill 0.3s;
}

.contact-825 .contact-element.contact-element-8b8f54b {
    --e-icon-list-icon-size: 16px;
    --icon-vertical-offset: 0px;
}

.contact-825 .contact-element.contact-element-8b8f54b .contact-icon-list-icon {
    padding-right: 14px;
}

.contact-825 .contact-element.contact-element-8b8f54b .contact-icon-list-text {
    transition: color 0.3s;
}

.contact-825 .contact-element.contact-element-8b8f54b>.contact-widget-container {
    margin: 0px 0px 10px 0px;
}

.contact-825 .contact-element.contact-element-c290742 {
    margin-top: 30px;
    margin-bottom: 0px;
    padding: 0px 0px 0px 0px;
}

.contact-825 .contact-element.contact-element-74d182a>.contact-element-populated {
    padding: 0px 0px 0px 0px;
}

.contact-825 .contact-element.contact-element-cf84311 iframe {
    height: 220px;
}

@media(min-width: 768px) {
    .contact-825 .contact-element.contact-element-fd63416 {
        width: 75%;
    }

    .contact-825 .contact-element.contact-element-9222f00 {
        width: 50%;
    }
}

@media(max-width: 1024px) and (min-width:768px) {
    .contact-825 .contact-element.contact-element-fd63416 {
        width: 100%;
    }

    .contact-825 .contact-element.contact-element-9222f00 {
        width: 100%;
    }
}

@media(max-width: 1024px) {
    .contact-825 .contact-element.contact-element-fd63416>.contact-element-populated {
        margin: 0px 0px 50px 0px;
        --e-column-margin-right: 0px;
        --e-column-margin-left: 0px;
    }

    .contact-825 .contact-element.contact-element-22d24c1 .edubin-section-title .section-title-txt {
        font-size: 42px;
    }

    .contact-825 .contact-element.contact-element-9222f00>.contact-element-populated {
        margin: 0px 0px 0px 0px;
        --e-column-margin-right: 0px;
        --e-column-margin-left: 0px;
    }

    .contact-825 .contact-element.contact-element-cf84311 iframe {
        height: 400px;
    }
}

@media(max-width: 767px) {
    .contact-825 .contact-element.contact-element-22d24c1 .edubin-section-title .section-title-txt {
        font-size: 30px;
        line-height: 1em;
        padding: 0px 0px 0px 0px;
    }

    .contact-825 .contact-element.contact-element-22d24c1>.contact-widget-container {
        margin: 0px 0px 0px 0px;
        padding: 0px 0px 50px 0px;
    }

    .contact-825 .contact-element.contact-element-4b2aeb3 .edubin-section-title span.edubin-title-sperator {
        margin: 15px 0px 0px 0px;
    }

    .contact-825 .contact-element.contact-element-4b2aeb3 .edubin-section-title .section-title-txt {
        font-size: 35px;
        line-height: 1em;
    }

    .contact-825 .contact-element.contact-element-cf84311 iframe {
        height: 200px;
    }
}


@media(max-width: 1024px) {
    .contact-section.contact-section-boxed>.contact-container {
        max-width: 1024px;
    }

    .e-con {
        --container-max-width: 1024px;
    }
}

@media(max-width: 767px) {
    .contact-section.contact-section-boxed>.contact-container {
        max-width: 767px;
    }

    .e-con {
        --container-max-width: 767px;
    }
}


:root {
    --page-title-display: block
}

.contact-page-title,
h1.entry-title {
    display: #000;
}

@keyframes eicon-spin {
    0% {
        transform: rotate(0deg)
    }

    to {
        transform: rotate(359deg)
    }
}

.eicon-animation-spin {
    animation: eicon-spin 2s linear infinite
}

.contact-section {
    position: relative
}

.contact-section .contact-container {
    display: flex;
    margin-right: auto;
    margin-left: auto;
    position: relative
}

@media (max-width: 1024px) {
    .contact-section .contact-container {
        flex-wrap: wrap
    }
}

.contact-section.contact-section-boxed>.contact-container {
    max-width: 1140px
}

.contact-section.contact-section-stretched {
    position: relative;
    width: 100%
}

.contact-section.contact-section-items-top>.contact-container {
    align-items: flex-start
}

.contact-section.contact-section-items-middle>.contact-container {
    align-items: center
}

.contact-section.contact-section-items-bottom>.contact-container {
    align-items: flex-end
}

@media (min-width: 768px) {
    .contact-section.contact-section-height-full {
        height: 100vh
    }

    .contact-section.contact-section-height-full>.contact-container {
        height: 100%
    }
}

.contact-bc-flex-widget .contact-section-content-top>.contact-container>.contact-column>.contact-widget-wrap {
    align-items: flex-start
}

.contact-bc-flex-widget .contact-section-content-middle>.contact-container>.contact-column>.contact-widget-wrap {
    align-items: center
}

.contact-bc-flex-widget .contact-section-content-bottom>.contact-container>.contact-column>.contact-widget-wrap {
    align-items: flex-end
}

.contact-row {
    width: 100%;
    display: flex
}

@media (max-width: 1024px) {
    .contact-row {
        flex-wrap: wrap
    }
}

.contact-widget-wrap {
    position: relative;
    width: 100%;
    flex-wrap: wrap;
    align-content: flex-start
}

.contact:not(.contact-bc-flex-widget) .contact-widget-wrap {
    display: flex
}

.contact-widget-wrap>.contact-element {
    width: 100%
}

.contact-widget-wrap.e-swiper-container {
    width: calc(100% - (var(--e-column-margin-left, 0px) + var(--e-column-margin-right, 0px)))
}

.contact-widget {
    position: relative
}

.contact-widget:not(:last-child) {
    margin-bottom: 0px
}

.contact-widget:not(:last-child).contact-absolute,
.contact-widget:not(:last-child).contact-widget__width-auto,
.contact-widget:not(:last-child).contact-widget__width-initial {
    margin-bottom: 0
}

.contact-column {
    position: relative;
    min-height: 1px;
    display: flex
}

.contact-column-wrap {
    width: 100%;
    position: relative;
    display: flex
}

.contact-column-gap-narrow>.contact-column>.contact-element-populated {
    padding: 10px
}

.contact-column-gap-default>.contact-column>.contact-element-populated {
    padding: 1px
}

.contact-column-gap-extended>.contact-column>.contact-element-populated {
    padding: 15px
}

.contact-column-gap-wide>.contact-column>.contact-element-populated {
    padding: 20px
}

.contact-column-gap-wider>.contact-column>.contact-element-populated {
    padding: 30px
}

.contact-inner-section .contact-column-gap-no .contact-element-populated {
    padding: 0
}

@media (min-width: 768px) {

    .contact-column.contact-col-10,
    .contact-column[data-col="10"] {
        width: 10%
    }

    .contact-column.contact-col-11,
    .contact-column[data-col="11"] {
        width: 11.111%
    }

    .contact-column.contact-col-12,
    .contact-column[data-col="12"] {
        width: 12.5%
    }

    .contact-column.contact-col-14,
    .contact-column[data-col="14"] {
        width: 14.285%
    }

    .contact-column.contact-col-16,
    .contact-column[data-col="16"] {
        width: 16.666%
    }

    .contact-column.contact-col-20,
    .contact-column[data-col="20"] {
        width: 20%
    }

    .contact-column.contact-col-25,
    .contact-column[data-col="25"] {
        width: 25%
    }

    .contact-column.contact-col-30,
    .contact-column[data-col="30"] {
        width: 30%
    }

    .contact-column.contact-col-33,
    .contact-column[data-col="33"] {
        width: 33.333%
    }

    .contact-column.contact-col-40,
    .contact-column[data-col="40"] {
        width: 40%
    }

    .contact-column.contact-col-50,
    .contact-column[data-col="50"] {
        width: 50%
    }

    .contact-column.contact-col-60,
    .contact-column[data-col="60"] {
        width: 60%
    }

    .contact-column.contact-col-66,
    .contact-column[data-col="66"] {
        width: 66.666%
    }

    .contact-column.contact-col-70,
    .contact-column[data-col="70"] {
        width: 70%
    }

    .contact-column.contact-col-75,
    .contact-column[data-col="75"] {
        width: 75%
    }

    .contact-column.contact-col-80,
    .contact-column[data-col="80"] {
        width: 80%
    }

    .contact-column.contact-col-83,
    .contact-column[data-col="83"] {
        width: 83.333%
    }

    .contact-column.contact-col-90,
    .contact-column[data-col="90"] {
        width: 90%
    }

    .contact-column.contact-col-100,
    .contact-column[data-col="100"] {
        width: 100%
    }
}

@media (max-width: 479px) {
    .contact-column.contact-xs-10 {
        width: 10%
    }

    .contact-column.contact-xs-11 {
        width: 11.111%
    }

    .contact-column.contact-xs-12 {
        width: 12.5%
    }

    .contact-column.contact-xs-14 {
        width: 14.285%
    }

    .contact-column.contact-xs-16 {
        width: 16.666%
    }

    .contact-column.contact-xs-20 {
        width: 20%
    }

    .contact-column.contact-xs-25 {
        width: 25%
    }

    .contact-column.contact-xs-30 {
        width: 30%
    }

    .contact-column.contact-xs-33 {
        width: 33.333%
    }

    .contact-column.contact-xs-40 {
        width: 40%
    }

    .contact-column.contact-xs-50 {
        width: 50%
    }

    .contact-column.contact-xs-60 {
        width: 60%
    }

    .contact-column.contact-xs-66 {
        width: 66.666%
    }

    .contact-column.contact-xs-70 {
        width: 70%
    }

    .contact-column.contact-xs-75 {
        width: 75%
    }

    .contact-column.contact-xs-80 {
        width: 80%
    }

    .contact-column.contact-xs-83 {
        width: 83.333%
    }

    .contact-column.contact-xs-90 {
        width: 90%
    }

    .contact-column.contact-xs-100 {
        width: 100%
    }
}

@media (max-width: 767px) {
    .contact-column.contact-sm-10 {
        width: 10%
    }

    .contact-column.contact-sm-11 {
        width: 11.111%
    }

    .contact-column.contact-sm-12 {
        width: 12.5%
    }

    .contact-column.contact-sm-14 {
        width: 14.285%
    }

    .contact-column.contact-sm-16 {
        width: 16.666%
    }

    .contact-column.contact-sm-20 {
        width: 20%
    }

    .contact-column.contact-sm-25 {
        width: 25%
    }

    .contact-column.contact-sm-30 {
        width: 30%
    }

    .contact-column.contact-sm-33 {
        width: 33.333%
    }

    .contact-column.contact-sm-40 {
        width: 40%
    }

    .contact-column.contact-sm-50 {
        width: 50%
    }

    .contact-column.contact-sm-60 {
        width: 60%
    }

    .contact-column.contact-sm-66 {
        width: 66.666%
    }

    .contact-column.contact-sm-70 {
        width: 70%
    }

    .contact-column.contact-sm-75 {
        width: 75%
    }

    .contact-column.contact-sm-80 {
        width: 80%
    }

    .contact-column.contact-sm-83 {
        width: 83.333%
    }

    .contact-column.contact-sm-90 {
        width: 90%
    }

    .contact-column.contact-sm-100 {
        width: 100%
    }
}

@media (min-width: 768px) and (max-width:1024px) {
    .contact-column.contact-md-10 {
        width: 10%
    }

    .contact-column.contact-md-11 {
        width: 11.111%
    }

    .contact-column.contact-md-12 {
        width: 12.5%
    }

    .contact-column.contact-md-14 {
        width: 14.285%
    }

    .contact-column.contact-md-16 {
        width: 16.666%
    }

    .contact-column.contact-md-20 {
        width: 20%
    }

    .contact-column.contact-md-25 {
        width: 25%
    }

    .contact-column.contact-md-30 {
        width: 30%
    }

    .contact-column.contact-md-33 {
        width: 33.333%
    }

    .contact-column.contact-md-40 {
        width: 40%
    }

    .contact-column.contact-md-50 {
        width: 50%
    }

    .contact-column.contact-md-60 {
        width: 60%
    }

    .contact-column.contact-md-66 {
        width: 66.666%
    }

    .contact-column.contact-md-70 {
        width: 70%
    }

    .contact-column.contact-md-75 {
        width: 75%
    }

    .contact-column.contact-md-80 {
        width: 80%
    }

    .contact-column.contact-md-83 {
        width: 83.333%
    }

    .contact-column.contact-md-90 {
        width: 90%
    }

    .contact-column.contact-md-100 {
        width: 100%
    }
}

@media (min-width: -1) {
    .contact-reverse-widescreen>.contact-container>:first-child {
        order: 10
    }

    .contact-reverse-widescreen>.contact-container>:nth-child(2) {
        order: 9
    }

    .contact-reverse-widescreen>.contact-container>:nth-child(3) {
        order: 8
    }

    .contact-reverse-widescreen>.contact-container>:nth-child(4) {
        order: 7
    }

    .contact-reverse-widescreen>.contact-container>:nth-child(5) {
        order: 6
    }

    .contact-reverse-widescreen>.contact-container>:nth-child(6) {
        order: 5
    }

    .contact-reverse-widescreen>.contact-container>:nth-child(7) {
        order: 4
    }

    .contact-reverse-widescreen>.contact-container>:nth-child(8) {
        order: 3
    }

    .contact-reverse-widescreen>.contact-container>:nth-child(9) {
        order: 2
    }

    .contact-reverse-widescreen>.contact-container>:nth-child(10) {
        order: 1
    }
}

@media (min-width: 1025px) and (max-width:-1) {
    .contact-reverse-laptop>.contact-container>:first-child {
        order: 10
    }

    .contact-reverse-laptop>.contact-container>:nth-child(2) {
        order: 9
    }

    .contact-reverse-laptop>.contact-container>:nth-child(3) {
        order: 8
    }

    .contact-reverse-laptop>.contact-container>:nth-child(4) {
        order: 7
    }

    .contact-reverse-laptop>.contact-container>:nth-child(5) {
        order: 6
    }

    .contact-reverse-laptop>.contact-container>:nth-child(6) {
        order: 5
    }

    .contact-reverse-laptop>.contact-container>:nth-child(7) {
        order: 4
    }

    .contact-reverse-laptop>.contact-container>:nth-child(8) {
        order: 3
    }

    .contact-reverse-laptop>.contact-container>:nth-child(9) {
        order: 2
    }

    .contact-reverse-laptop>.contact-container>:nth-child(10) {
        order: 1
    }
}

@media (min-width: -1) and (max-width:-1) {
    .contact-reverse-laptop>.contact-container>:first-child {
        order: 10
    }

    .contact-reverse-laptop>.contact-container>:nth-child(2) {
        order: 9
    }

    .contact-reverse-laptop>.contact-container>:nth-child(3) {
        order: 8
    }

    .contact-reverse-laptop>.contact-container>:nth-child(4) {
        order: 7
    }

    .contact-reverse-laptop>.contact-container>:nth-child(5) {
        order: 6
    }

    .contact-reverse-laptop>.contact-container>:nth-child(6) {
        order: 5
    }

    .contact-reverse-laptop>.contact-container>:nth-child(7) {
        order: 4
    }

    .contact-reverse-laptop>.contact-container>:nth-child(8) {
        order: 3
    }

    .contact-reverse-laptop>.contact-container>:nth-child(9) {
        order: 2
    }

    .contact-reverse-laptop>.contact-container>:nth-child(10) {
        order: 1
    }
}

@media (min-width: 1025px) and (max-width:-1) {

    .contact-reverse-laptop>.contact-container>:first-child,
    .contact-reverse-laptop>.contact-container>:nth-child(2),
    .contact-reverse-laptop>.contact-container>:nth-child(3),
    .contact-reverse-laptop>.contact-container>:nth-child(4),
    .contact-reverse-laptop>.contact-container>:nth-child(5),
    .contact-reverse-laptop>.contact-container>:nth-child(6),
    .contact-reverse-laptop>.contact-container>:nth-child(7),
    .contact-reverse-laptop>.contact-container>:nth-child(8),
    .contact-reverse-laptop>.contact-container>:nth-child(9),
    .contact-reverse-laptop>.contact-container>:nth-child(10) {
        order: 0
    }

    .contact-reverse-tablet_extra>.contact-container>:first-child {
        order: 10
    }

    .contact-reverse-tablet_extra>.contact-container>:nth-child(2) {
        order: 9
    }

    .contact-reverse-tablet_extra>.contact-container>:nth-child(3) {
        order: 8
    }

    .contact-reverse-tablet_extra>.contact-container>:nth-child(4) {
        order: 7
    }

    .contact-reverse-tablet_extra>.contact-container>:nth-child(5) {
        order: 6
    }

    .contact-reverse-tablet_extra>.contact-container>:nth-child(6) {
        order: 5
    }

    .contact-reverse-tablet_extra>.contact-container>:nth-child(7) {
        order: 4
    }

    .contact-reverse-tablet_extra>.contact-container>:nth-child(8) {
        order: 3
    }

    .contact-reverse-tablet_extra>.contact-container>:nth-child(9) {
        order: 2
    }

    .contact-reverse-tablet_extra>.contact-container>:nth-child(10) {
        order: 1
    }
}

@media (min-width: 768px) and (max-width:1024px) {
    .contact-reverse-tablet>.contact-container>:first-child {
        order: 10
    }

    .contact-reverse-tablet>.contact-container>:nth-child(2) {
        order: 9
    }

    .contact-reverse-tablet>.contact-container>:nth-child(3) {
        order: 8
    }

    .contact-reverse-tablet>.contact-container>:nth-child(4) {
        order: 7
    }

    .contact-reverse-tablet>.contact-container>:nth-child(5) {
        order: 6
    }

    .contact-reverse-tablet>.contact-container>:nth-child(6) {
        order: 5
    }

    .contact-reverse-tablet>.contact-container>:nth-child(7) {
        order: 4
    }

    .contact-reverse-tablet>.contact-container>:nth-child(8) {
        order: 3
    }

    .contact-reverse-tablet>.contact-container>:nth-child(9) {
        order: 2
    }

    .contact-reverse-tablet>.contact-container>:nth-child(10) {
        order: 1
    }
}

@media (min-width: -1) and (max-width:1024px) {
    .contact-reverse-tablet>.contact-container>:first-child {
        order: 10
    }

    .contact-reverse-tablet>.contact-container>:nth-child(2) {
        order: 9
    }

    .contact-reverse-tablet>.contact-container>:nth-child(3) {
        order: 8
    }

    .contact-reverse-tablet>.contact-container>:nth-child(4) {
        order: 7
    }

    .contact-reverse-tablet>.contact-container>:nth-child(5) {
        order: 6
    }

    .contact-reverse-tablet>.contact-container>:nth-child(6) {
        order: 5
    }

    .contact-reverse-tablet>.contact-container>:nth-child(7) {
        order: 4
    }

    .contact-reverse-tablet>.contact-container>:nth-child(8) {
        order: 3
    }

    .contact-reverse-tablet>.contact-container>:nth-child(9) {
        order: 2
    }

    .contact-reverse-tablet>.contact-container>:nth-child(10) {
        order: 1
    }
}

@media (min-width: 768px) and (max-width:-1) {

    .contact-reverse-tablet>.contact-container>:first-child,
    .contact-reverse-tablet>.contact-container>:nth-child(2),
    .contact-reverse-tablet>.contact-container>:nth-child(3),
    .contact-reverse-tablet>.contact-container>:nth-child(4),
    .contact-reverse-tablet>.contact-container>:nth-child(5),
    .contact-reverse-tablet>.contact-container>:nth-child(6),
    .contact-reverse-tablet>.contact-container>:nth-child(7),
    .contact-reverse-tablet>.contact-container>:nth-child(8),
    .contact-reverse-tablet>.contact-container>:nth-child(9),
    .contact-reverse-tablet>.contact-container>:nth-child(10) {
        order: 0
    }

    .contact-reverse-mobile_extra>.contact-container>:first-child {
        order: 10
    }

    .contact-reverse-mobile_extra>.contact-container>:nth-child(2) {
        order: 9
    }

    .contact-reverse-mobile_extra>.contact-container>:nth-child(3) {
        order: 8
    }

    .contact-reverse-mobile_extra>.contact-container>:nth-child(4) {
        order: 7
    }

    .contact-reverse-mobile_extra>.contact-container>:nth-child(5) {
        order: 6
    }

    .contact-reverse-mobile_extra>.contact-container>:nth-child(6) {
        order: 5
    }

    .contact-reverse-mobile_extra>.contact-container>:nth-child(7) {
        order: 4
    }

    .contact-reverse-mobile_extra>.contact-container>:nth-child(8) {
        order: 3
    }

    .contact-reverse-mobile_extra>.contact-container>:nth-child(9) {
        order: 2
    }

    .contact-reverse-mobile_extra>.contact-container>:nth-child(10) {
        order: 1
    }
}

@media (max-width: 767px) {
    .contact-reverse-mobile>.contact-container>:first-child {
        order: 10
    }

    .contact-reverse-mobile>.contact-container>:nth-child(2) {
        order: 9
    }

    .contact-reverse-mobile>.contact-container>:nth-child(3) {
        order: 8
    }

    .contact-reverse-mobile>.contact-container>:nth-child(4) {
        order: 7
    }

    .contact-reverse-mobile>.contact-container>:nth-child(5) {
        order: 6
    }

    .contact-reverse-mobile>.contact-container>:nth-child(6) {
        order: 5
    }

    .contact-reverse-mobile>.contact-container>:nth-child(7) {
        order: 4
    }

    .contact-reverse-mobile>.contact-container>:nth-child(8) {
        order: 3
    }

    .contact-reverse-mobile>.contact-container>:nth-child(9) {
        order: 2
    }

    .contact-reverse-mobile>.contact-container>:nth-child(10) {
        order: 1
    }

    .contact-column {
        width: 100%
    }
}

.contact_container {
    background-color: #e7e6e6;
}

/* Map Styles Starts */

.map {
    height: 450px;
    width: 100%;
}

.map-heading {
    margin-left: 60px;
}





/* CSSS */

.contact-widget.contact-icon-list--layout-inline .contact-widget-container {
    overflow: hidden
}

.contact-widget .contact-icon-list-items.contact-inline-items {
    margin-right: -8px;
    margin-left: -8px
}

.contact-widget .contact-icon-list-items.contact-inline-items .contact-icon-list-item {
    margin-right: 8px;
    margin-left: 8px
}

.contact-widget .contact-icon-list-items.contact-inline-items .contact-icon-list-item:after {
    width: auto;
    left: auto;
    right: auto;
    position: relative;
    height: 100%;
    border-top: 0;
    border-bottom: 0;
    border-right: 0;
    border-left-width: 1px;
    border-style: solid;
    right: -8px
}

.contact-widget .contact-icon-list-items {
    list-style-type: none;
    margin: 0;
    padding: 0
}

.contact-widget .contact-icon-list-item {
    margin: 0;
    padding: 0;
    position: relative
}

.contact-widget .contact-icon-list-item:after {
    position: absolute;
    bottom: 0;
    width: 100%
}

.contact-widget .contact-icon-list-item,
.contact-widget .contact-icon-list-item a {
    display: flex;
    font-size: inherit;
    align-items: var(--icon-vertical-align, center)
}

.contact-widget .contact-icon-list-icon+.contact-icon-list-text {
    align-self: center;
    padding-inline-start: 5px
}

.contact-widget .contact-icon-list-icon {
    display: flex;
    position: relative;
    top: var(--icon-vertical-offset, initial)
}

.contact-widget .contact-icon-list-icon svg {
    width: var(--e-icon-list-icon-size, 1em);
    height: var(--e-icon-list-icon-size, 1em)
}

.contact-widget .contact-icon-list-icon i {
    width: 1.25em;
    font-size: var(--e-icon-list-icon-size)
}

.contact-widget.contact-widget-icon-list .contact-icon-list-icon {
    text-align: var(--e-icon-list-icon-align)
}

.contact-widget.contact-widget-icon-list .contact-icon-list-icon svg {
    margin: var(--e-icon-list-icon-margin, 0 calc(var(--e-icon-list-icon-size, 1em) * .25) 0 0)
}

.contact-widget.contact-list-item-link-full_width a {
    width: 100%
}

.contact-widget.contact-align-center .contact-icon-list-item,
.contact-widget.contact-align-center .contact-icon-list-item a {
    justify-content: center
}

.contact-widget.contact-align-center .contact-icon-list-item:after {
    margin: auto
}

.contact-widget.contact-align-center .contact-inline-items {
    justify-content: center
}

.contact-widget.contact-align-left .contact-icon-list-item,
.contact-widget.contact-align-left .contact-icon-list-item a {
    justify-content: flex-start;
    text-align: left
}

.contact-widget.contact-align-left .contact-inline-items {
    justify-content: flex-start
}

.contact-widget.contact-align-right .contact-icon-list-item,
.contact-widget.contact-align-right .contact-icon-list-item a {
    justify-content: flex-end;
    text-align: right
}

.contact-widget.contact-align-right .contact-icon-list-items {
    justify-content: flex-end
}

.contact-widget:not(.contact-align-right) .contact-icon-list-item:after {
    left: 0
}

.contact-widget:not(.contact-align-left) .contact-icon-list-item:after {
    right: 0
}

@media (min-width:-1) {

    .contact-widget.contact-widescreen-align-center .contact-icon-list-item,
    .contact-widget.contact-widescreen-align-center .contact-icon-list-item a {
        justify-content: center
    }

    .contact-widget.contact-widescreen-align-center .contact-icon-list-item:after {
        margin: auto
    }

    .contact-widget.contact-widescreen-align-center .contact-inline-items {
        justify-content: center
    }

    .contact-widget.contact-widescreen-align-left .contact-icon-list-item,
    .contact-widget.contact-widescreen-align-left .contact-icon-list-item a {
        justify-content: flex-start;
        text-align: left
    }

    .contact-widget.contact-widescreen-align-left .contact-inline-items {
        justify-content: flex-start
    }

    .contact-widget.contact-widescreen-align-right .contact-icon-list-item,
    .contact-widget.contact-widescreen-align-right .contact-icon-list-item a {
        justify-content: flex-end;
        text-align: right
    }

    .contact-widget.contact-widescreen-align-right .contact-icon-list-items {
        justify-content: flex-end
    }

    .contact-widget:not(.contact-widescreen-align-right) .contact-icon-list-item:after {
        left: 0
    }

    .contact-widget:not(.contact-widescreen-align-left) .contact-icon-list-item:after {
        right: 0
    }
}

@media (max-width:-1) {

    .contact-widget.contact-laptop-align-center .contact-icon-list-item,
    .contact-widget.contact-laptop-align-center .contact-icon-list-item a {
        justify-content: center
    }

    .contact-widget.contact-laptop-align-center .contact-icon-list-item:after {
        margin: auto
    }

    .contact-widget.contact-laptop-align-center .contact-inline-items {
        justify-content: center
    }

    .contact-widget.contact-laptop-align-left .contact-icon-list-item,
    .contact-widget.contact-laptop-align-left .contact-icon-list-item a {
        justify-content: flex-start;
        text-align: left
    }

    .contact-widget.contact-laptop-align-left .contact-inline-items {
        justify-content: flex-start
    }

    .contact-widget.contact-laptop-align-right .contact-icon-list-item,
    .contact-widget.contact-laptop-align-right .contact-icon-list-item a {
        justify-content: flex-end;
        text-align: right
    }

    .contact-widget.contact-laptop-align-right .contact-icon-list-items {
        justify-content: flex-end
    }

    .contact-widget:not(.contact-laptop-align-right) .contact-icon-list-item:after {
        left: 0
    }

    .contact-widget:not(.contact-laptop-align-left) .contact-icon-list-item:after {
        right: 0
    }
}

@media (max-width:-1) {

    .contact-widget.contact-tablet_extra-align-center .contact-icon-list-item,
    .contact-widget.contact-tablet_extra-align-center .contact-icon-list-item a {
        justify-content: center
    }

    .contact-widget.contact-tablet_extra-align-center .contact-icon-list-item:after {
        margin: auto
    }

    .contact-widget.contact-tablet_extra-align-center .contact-inline-items {
        justify-content: center
    }

    .contact-widget.contact-tablet_extra-align-left .contact-icon-list-item,
    .contact-widget.contact-tablet_extra-align-left .contact-icon-list-item a {
        justify-content: flex-start;
        text-align: left
    }

    .contact-widget.contact-tablet_extra-align-left .contact-inline-items {
        justify-content: flex-start
    }

    .contact-widget.contact-tablet_extra-align-right .contact-icon-list-item,
    .contact-widget.contact-tablet_extra-align-right .contact-icon-list-item a {
        justify-content: flex-end;
        text-align: right
    }

    .contact-widget.contact-tablet_extra-align-right .contact-icon-list-items {
        justify-content: flex-end
    }

    .contact-widget:not(.contact-tablet_extra-align-right) .contact-icon-list-item:after {
        left: 0
    }

    .contact-widget:not(.contact-tablet_extra-align-left) .contact-icon-list-item:after {
        right: 0
    }
}

@media (max-width:1024px) {

    .contact-widget.contact-tablet-align-center .contact-icon-list-item,
    .contact-widget.contact-tablet-align-center .contact-icon-list-item a {
        justify-content: center
    }

    .contact-widget.contact-tablet-align-center .contact-icon-list-item:after {
        margin: auto
    }

    .contact-widget.contact-tablet-align-center .contact-inline-items {
        justify-content: center
    }

    .contact-widget.contact-tablet-align-left .contact-icon-list-item,
    .contact-widget.contact-tablet-align-left .contact-icon-list-item a {
        justify-content: flex-start;
        text-align: left
    }

    .contact-widget.contact-tablet-align-left .contact-inline-items {
        justify-content: flex-start
    }

    .contact-widget.contact-tablet-align-right .contact-icon-list-item,
    .contact-widget.contact-tablet-align-right .contact-icon-list-item a {
        justify-content: flex-end;
        text-align: right
    }

    .contact-widget.contact-tablet-align-right .contact-icon-list-items {
        justify-content: flex-end
    }

    .contact-widget:not(.contact-tablet-align-right) .contact-icon-list-item:after {
        left: 0
    }

    .contact-widget:not(.contact-tablet-align-left) .contact-icon-list-item:after {
        right: 0
    }
}

@media (max-width:-1) {

    .contact-widget.contact-mobile_extra-align-center .contact-icon-list-item,
    .contact-widget.contact-mobile_extra-align-center .contact-icon-list-item a {
        justify-content: center
    }

    .contact-widget.contact-mobile_extra-align-center .contact-icon-list-item:after {
        margin: auto
    }

    .contact-widget.contact-mobile_extra-align-center .contact-inline-items {
        justify-content: center
    }

    .contact-widget.contact-mobile_extra-align-left .contact-icon-list-item,
    .contact-widget.contact-mobile_extra-align-left .contact-icon-list-item a {
        justify-content: flex-start;
        text-align: left
    }

    .contact-widget.contact-mobile_extra-align-left .contact-inline-items {
        justify-content: flex-start
    }

    .contact-widget.contact-mobile_extra-align-right .contact-icon-list-item,
    .contact-widget.contact-mobile_extra-align-right .contact-icon-list-item a {
        justify-content: flex-end;
        text-align: right
    }

    .contact-widget.contact-mobile_extra-align-right .contact-icon-list-items {
        justify-content: flex-end
    }

    .contact-widget:not(.contact-mobile_extra-align-right) .contact-icon-list-item:after {
        left: 0
    }

    .contact-widget:not(.contact-mobile_extra-align-left) .contact-icon-list-item:after {
        right: 0
    }
}

@media (max-width:767px) {

    .contact-widget.contact-mobile-align-center .contact-icon-list-item,
    .contact-widget.contact-mobile-align-center .contact-icon-list-item a {
        justify-content: center
    }

    .contact-widget.contact-mobile-align-center .contact-icon-list-item:after {
        margin: auto
    }

    .contact-widget.contact-mobile-align-center .contact-inline-items {
        justify-content: center
    }

    .contact-widget.contact-mobile-align-left .contact-icon-list-item,
    .contact-widget.contact-mobile-align-left .contact-icon-list-item a {
        justify-content: flex-start;
        text-align: left
    }

    .contact-widget.contact-mobile-align-left .contact-inline-items {
        justify-content: flex-start
    }

    .contact-widget.contact-mobile-align-right .contact-icon-list-item,
    .contact-widget.contact-mobile-align-right .contact-icon-list-item a {
        justify-content: flex-end;
        text-align: right
    }

    .contact-widget.contact-mobile-align-right .contact-icon-list-items {
        justify-content: flex-end
    }

    .contact-widget:not(.contact-mobile-align-right) .contact-icon-list-item:after {
        left: 0
    }

    .contact-widget:not(.contact-mobile-align-left) .contact-icon-list-item:after {
        right: 0
    }
}