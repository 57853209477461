


/* 320px — 480px: Mobile devices */
@media screen and (min-width: 320px) and (max-width: 480px) {
    .hero-documents {
        margin-top: 1.2rem;
        height: 40vh;
        padding: 6.5em 0;
    }



}

/* 481px — 768px: iPads, Tablets. */
@media screen and (min-width: 481px) and (max-width: 768px) {}