/* latest news */
.latest-title,
.current-date {
  background-color: #d2b356;
  padding: 8px 14px;
}

.latest-title i {
  padding-right: 5px;
}

.latest--news {
  font-size: 13px;
  background-color: #598abf;
}


.news-updates-list {
  width: 100%;
}

.news-updates-list li:not(:last-child) {
  margin-right: 20px;
}

.latest--news .date-text {
  color: #47e647;
}


@media (max-width: 992px) {

  .latest-title,
  .current-date {
    width: 120px;
    background-color: #d2b356;
    font-size: 8px;
  }
}