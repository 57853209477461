.profile-container {
    margin: 3.5em 0;

}

.profile-container p {
    padding-top: 0.5em;
    text-align: justify;
}

.profile-container h2 {
    text-align: left;
}


.profile-container h4 {
    font-weight: 900;
}


.profile-container .image-container img {
    transition: all 0.5s ease 0s;
    margin: 0 auto;
    width: 100%;
}

.profile-container .image-container img:hover {
    translate: 10px -10px;
    -webkit-transform: scale(0.7, 0.7);
    transform: scale(0.7, 0.7);
}

.profile-container .image-container {
    width: 100%;
    height: 600px;
    border-radius: 15px 15px 0 0;
    clip-path: polygon(0px 0px, 0.27% 77.17%, 103.72% 89.00%, 99.92% 0.81%);
    background: rgb(0, 0, 0);
    overflow: hidden;
}

.profile-container img {
    width: 100%;
    display: block;
}

