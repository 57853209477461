/* 7. FAQ page */
.faq {
    /* background: #f9f6f7; */
    background: #fff;
    padding: 4rem 2rem
}


.fa {
    font-family: var(--fa-style-family, "Font Awesome 6 Free");
    font-weight: var(--fa-style, 900);
}

.faq-hero {
    margin-top: 1rem;
    background-image: linear-gradient(rgba(0, 0, 0, .2), rgba(0, 0, 0, .8)), url(../images/faq.jpg);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    padding: 12em 0;
    text-align: center;
    color: #ffffff;
    height: 26rem;
}

.faq-hero h1 {
    font-size: 2.5em;
    font-weight: 600;
}

.faq h3 {
    font-size: .8em;
    color: #333366;
    padding: 1rem;
    margin: 0
}

.faq ul {
    position: sticky;
    top: 100px;
    padding: 5px;
    margin: 10px auto;
    width: 90%
}

.faq ul li {
    background: #fff;
    font-size: 1.4rem;
    box-shadow: 0 .3rem .3rem rgba(0, 0, 0, .5);
    border: 1px solid #ecedf2;
    padding: 10px 5px;
    margin: 10px
}

.faq ul li a {
    padding: 15px
}

.faq .accordion-wrapper a {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    border: 1px solid #ecedf2;
    padding: 1rem 3rem 1.5rem 1rem;
    /* color: #7288A2; */
    color: #333366;
    font-size: 1.0em;
    background: #fff;
    box-shadow: 0 6px 15px rgba(64, 79, 104, .05);
    margin-bottom: 10px;
    border-bottom: 2px solid #DDD;
    text-decoration: none
}

.faq .accordion-wrapper a.active {
    color: #03B5D2;
    border-bottom: 1px solid #03B5D2
}

.faq .accordion-wrapper a:hover,
.accordion-wrappera:hover::after {
    cursor: pointer;
    color: #03B5D2
}

.faq .accordion-wrapper .accordion:after {
    content: '\02b';
    font-size: 24px !important; /* Adjust the font size */
    font-weight: 500; /* Adjust the font weight */
    position: absolute;
    float: right;
    right: 1rem;
    color: #000;
    border: none
}

.faq .accordion-wrapper .active:after {
    content: '\002D';
    font-size: 26px !important; /* Adjust the font size */
    font-weight: 500; /* Adjust the font weight */
}

.faq .accordion-wrapper .content p {
    font-size: 1rem;
    font-weight: 400
}

.faq .accordion-wrapper .content {
    padding: 0 18px;
    background-color: white;
    display: none;
    overflow: hidden
}


/* FAQ Card */

#accordion-faqs {
    margin-top: 30px;
}

#accordion-faqs .card {
    margin-top: 20px;
    border: 1px solid #ecedf2;
    box-shadow: 0 6px 15px rgba(64,79,104,.05);
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
}

#accordion-faqs .card-header {
    padding: 15px 30px;
    background-color: transparent;
    border: 0;
}

#accordion-faqs .card-title {
    margin: 0;
    font-size: 18px;
    cursor: pointer;
}

#accordion-faqs .card-title,
#accordion-faqs .card-title a,
#accordion-faqs .card-title a:hover,
#accordion-faqs .card-title a:focus {
    color: #333;
    padding: 5px 5px 5px 0;
    font-weight: 600;
}

#accordion-faqs .card-body {
    padding: 30px 35px 20px;
    border-top: 1px solid #ecedf2;
    font-size: 17px;
}
