.hero-executive h1 {
    font-size: 2.5em;
    font-weight: 600;
}

.ECoL_team .team_member_box {
    width: 250px;
    height: 390px;
    color: #333;
    position: relative;
    background-color: #f1f1f1;
    margin: 1.5em 0em;

}

.ECoL_team .team_member_box .team_member_pic {
    overflow: hidden;

    height: 210px;
    background-color: rgb(0, 0, 0);
    text-align: center;
    transition: all .3s ease-in-out;
}

.ECoL_team .team_member_box .team_member_pic img {
    transition: all 0.3s ease 0s;
    margin: 0 auto;
    width: 100%;
}

.ECoL_team .team_member_box .team_member_pic img:hover {
    -webkit-box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
    -webkit-transform: scale(1.05, 1.05);
    transform: scale(1.05, 1.05);
}

.ECoL_team .team_member_info {
    padding: 10px;
    text-align: center;
    border-top: 1px solid #eee;
}

.ECoL_team .team_member_info h5 {
    font-size: 16px;
    margin: 0;
}

.ECoL_team .team_member_box strong {
    color: #444;
}

.ECoL_team .team_member_box p {
    font-size: 13px !important;
    margin: 0;
    line-height: 22px;
}

.ECoL_team .team_member_box .team_member_info .text-info {
    color: #598abf !important;
}


.team_member_pic {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    min-height: 17.125rem;
    background: linear-gradient(271.65deg, #757575 1.32%, #757575 98.99%);
}

.item-image {
    width: 12.875rem;
    min-width: 12.875rem;
    height: 12.7325rem;
    background-image: url(../../images/frame.svg);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    padding: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.item-frame {
    width: 100%;
    height: 100%;
    display: flex;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top center;
}



/* Featured Doctors Section Styles Starts */
/* -------------------------------------- */

.featured-executives {
    padding: 25px;
    text-align: center;
    position: relative;
    background-size: cover;
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-position: center;
    z-index: 1;
}

.featured-executives:before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.75);
    z-index: 1;
}

.featured-executives h2,
.featured-executives h2+p {
    color: #fff;
}

.featured-executives h2+p {
    margin-left: 5%;
    margin-right: 5%;
}

/* Doctors Bio Boxes Styles Starts */
/* ------------------------------ */

.bio-box {
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    position: relative;
    z-index: 1;
    margin-bottom: 25px;
}

.bio-box .dlab-border-left:after,
.bio-box .dlab-border-left:before,
.bio-box .dlab-border-right:after,
.bio-box .dlab-border-right:before {
    content: '';
    position: absolute;
    width: 30px;
    height: 30px;
    opacity: 1;
    border: 4px solid #598abf;
}

.bio-box .dlab-border-left:after {
    bottom: 0;
    left: 0;
    border-width: 0 0 4px 4px !important;
}

.bio-box .dlab-border-left:before {
    right: 0;
    bottom: 0;
    border-width: 0 4px 4px 0 !important;
}

.bio-box .dlab-border-right:before {
    top: 0;
    right: 0;
    border-width: 4px 4px 0 0 !important;
}

.bio-box .dlab-border-right:after {
    left: 0;
    top: 0;
    border-width: 4px 0 0 4px !important;
}

.doctors-grid .bio-box {
    margin-top: 40px;
}

.bio-box .profile-img {
    flex: 1;
    overflow: hidden;
    position: relative;
    padding: 10px;
}

.bio-box .dlab-media {
    position: relative;
    height: 15rem;
    width: 13rem;
}

/* .bio-box .dlab-media:before {
    content: " ";
    background: rgba(0, 0, 0, .5);
    position: absolute;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
} */


.bio-box .dlab-media:after {
    content: "";
    background: rgba(0, 0, 0, .7);
    position: absolute;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: all .4s;
    -moz-transition: all .4s;
    transition: all .4s;
}

/* Base styles for the .dlab-media::before pseudo-element */
/* .bio-box .dlab-media:before {
    content: " ";
    background: rgba(0, 0, 0, 0.5);
    position: absolute;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    opacity: 1;
    transition: opacity 0.7s ease;
} */


/* @keyframes removeOpacity {
    from {
        opacity: 1;
        background: rgba(0, 0, 0, 0.5);
        clip-path: inset(0 100% 0 0);
    }
    to {
        opacity: 0;
        background: rgba(0, 0, 0, 0);
        clip-path: inset(0 0 0 0);
    }
} */

/* Apply the animation on hover */
.bio-box:hover .dlab-media:before {
    animation: removeOpacity 1.05s forwards;
    /* Run animation on hover */
}

.bio-box:hover .dlab-media::after {
    visibility: visible;
    opacity: 1;
}

/* 
.bio-box .dlab-media::before {
    visibility: visible;
    opacity: 1;
} */

.profile-img .overlay {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: -69px;
    margin-top: -30px;
    background: var(--thm-primary);
    padding: 10px 30px;
    transform: scale(0.4);
    opacity: 0;
    border-radius: 30px 0;
    z-index: 5;
}

.bio-box:hover .overlay {
    transform: scale(1);
    opacity: 1;
}

.bio-box .profile-img .overlay ul.sm-links {
    position: relative;
    text-align: center;
    margin-top: 4px;
    margin-bottom: 0;
}

.bio-box .profile-img .overlay ul.sm-links li {
    padding: 0;
    line-height: 20px;
}

.bio-box .profile-img .overlay ul.sm-links li a i {
    font-size: 20px;
    color: #598abf;
}

.bio-box .profile-img .overlay ul.sm-links li a i:hover {
    color: #d2b356;
}

/* Text Holder Below Images */
.bio-box .txt-holder {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    position: relative;
    width: 12em;
    margin: 0px 20px;
    margin-top: -40px;
    padding: 5px 0px 5px 0px;
    z-index: 1;
    color: #fff;
}

.bio-box:hover .txt-holder {
    color: #000;
}

.bio-box .txt-overflow.txt-holder h5 {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.bio-box .txt-holder:before,
.bio-box .txt-holder:after {
    content: "";
    position: absolute;
    -webkit-transform: skew(-4deg);
    -moz-transform: skew(-4deg);
    transform: skew(-4deg);
    z-index: -1;
}

.bio-box .txt-holder:before {
    left: 0;
    bottom: 0;
    top: 0;
    right: 0;
    background-color: #598abf;
}

.bio-box .txt-holder:after {
    bottom: 0;
    right: 0;
    width: 0;
    height: 100%;
    background: linear-gradient(45deg, #b0c4de, #598abf);
    -webkit-transform-origin: left;
    -moz-transform-origin: left;
    transform-origin: left;
    -moz-transition: width .6s;
    -webkit-transition: width .6s;
    transition: width .6s;
}

.bio-box:hover .txt-holder:after {
    width: 100%;
    left: 0;
    right: auto;
    -moz-transform-origin: right;
    -webkit-transform-origin: right;
    transform-origin: right;
}

.bio-box .txt-holder p {
    word-wrap: break-word;
    overflow-wrap: break-word;
    max-width: 100%;
    font-size: 0.8em !important;
    line-height: 22px;
    margin: 0;
}

.bio-box h5 {
    margin-top: 0;
    margin-bottom: 0;
    font-weight: bold;
    font-size: 17px;
}

/* Biography Box Images */
.bio-box .img-fluid {
    background-size: cover;
    background-position: center;
    background-size: 100%;
    padding: 0px;
    background-color: lightgrey;
    display: block;
    position: relative;
    width: inherit;
    height: inherit;
    object-fit: cover;
    border-radius: 3px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.bio-box,
.team_member_box {
    position: relative;
    border-radius: 8px;
    align-items: center;
}

.bio-box .dlab-border-left:after,
.bio-box .dlab-border-left:before,
.bio-box .dlab-border-right:after,
.bio-box .dlab-border-right:before {
    content: '';
    position: absolute;
    width: 30px;
    height: 30px;
    opacity: 1;
    border: 4px solid #598abf;
}

.bio-box .dlab-border-left:before {
    right: 0;
    bottom: 0;
    border-width: 0 4px 4px 0;
}

@media screen and (max-width:576px) {
    .featured-executives {
        padding: 10px;
        text-align: center;
        position: relative;
        background-size: cover;
        background-attachment: fixed;
        background-repeat: no-repeat;
        background-position: center;
        z-index: 1;
    }
}

/* 768-820 */