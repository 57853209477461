/*------------------------------------------------------------------
    color theme
-------------------------------------------------------------------*/

.header_top,
#navbar_menu ul ul li a:hover,
#navbar_menu ul ul li a:focus,
.full_slider_inner,
.bg_load,
.theme_color_bg,
.price_table_active .price_head,
a.btn.dark_gray_bt:hover,
a.btn.dark_gray_bt:focus,
.carousel-indicators .active,
.social_icon_style_2 ul li a:hover,
.social_icon_style_2 ul li a:focus,
.progress-bar,
.tags a:hover,
.tags a:focus,
.testimonial_slider .carousel-control:hover,
.testimonial_slider .carousel-control:focus,
.pagination>.active>a,
.pagination>.active>a:focus,
.pagination>.active>a:hover,
.pagination>.active>span,
.pagination>.active>span:focus,
.pagination>.active>span:hover,
.icon_feature {
	background: #598abf;
	background-size: 100% 100%;
	border: none;
}

.theme_bg {
	background: #598abf;
}

#navbar_menu>ul>li a.active,
#navbar_menu>ul>li:hover>a,
#navbar_menu>ul>li:focus>a,
footer p a:hover,
footer p a:focus,
.footer-menu li a:hover,
.footer-menu li a:focus,
footer ul.social_icons li a:hover,
footer ul.social_icons li a:focus,
.breadcrumb li a {
	color: #598abf;
}

.banner_a {
	color: #598abf;
}


.btn.sqaure_bt {
	border-radius: 0;
	background: #598abf;
}

.btn.sqaure_bt span {
	font-size: 15px;
	font-weight: 400;
	position: absolute;
	right: 16px;
}


.tp-caption.tp-shape.tp-shapewrapper.tp-resizeme.rs-parallaxlevel-0 {
	background: #598abf !important;
}


#b0c4de a.btn.dark_gray_bt:hover,
a.btn.dark_gray_bt:focus {
	color: #fff;
}

.modal-backdrop {
	background-color: #598abf;
}

#navbar_menu ul ul,
hr.dottad_border,
.social_icon_style_2 ul li a:hover,
.social_icon_style_2 ul li a:focus,
.tags a:hover,
.tags a:focus,
.pagination>.active>a,
.pagination>.active>a:focus,
.pagination>.active>a:hover,
.pagination>.active>span,
.pagination>.active>span:focus,
.pagination>.active>span:hover,
.comment_section,
.boder_dottat_small {
	border-color: #598abf;
}


.price_table_active a.btn:hover,
.price_table_active a.btn:focus {
	background: #252525;
	color: #fff;
}

.border_dotted_circle:hover,
.border_dotted_circle:focus {
	border-color: #632e62;
}

.btn.light_theme_bt:hover,
.btn.light_theme_bt:focus {
	background: #222;
	color: #fff;
	border: solid #222 1px;
}

.theme_color_bg .border_dotted_circle,
.theme_color_bg .border_dotted_circle:hover,
.theme_color_bg .border_dotted_circle:focus {
	border-color: #fff;
}

.tp-bannertimer {
	background: #598abf !important;
}

.tp-caption.tp-shape.tp-shapewrapper.rs-parallaxlevel-0 {
	background-color: rgba(0, 0, 0, 0.4) !important;
}