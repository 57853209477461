@font-face {
  font-family: 'Roboto';
  src: url('./fonts/Roboto/Roboto-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

* {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box
}

body {
  font-size: 14px;
  font-family: Helvetica;
  color: #000;
  background: #f4f4f4;
  overflow-x: hidden;
  /* min-height: 150vh */
}

html,
body {
  scroll-behavior: smooth;
  scroll-padding-top: 5rem
}


article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
nav,
section {
  display: block
}

/* ol,
ul {
  list-style: none
} */

a[href],
label[for],
select,
input[type=checkbox],
input[type=radio] {
  cursor: pointer
}

button,
input[type=button],
input[type=image],
input[type=reset],
input[type=submit] {
  overflow: visible;
  cursor: pointer
}

button::-moz-focus-inner,
input[type=button]::-moz-focus-inner,
input[type=image]::-moz-focus-inner,
input[type=reset]::-moz-focus-inner,
input[type=submit]::-moz-focus-inner {
  border: 0
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: normal;
  font-family: 'Roboto'
}



h1 {
  font-weight: 600;
  font-size: 25px
}

h2 {
  font-weight: 700;
  position: relative;
}


h3 {
  font-weight: 400;
  font-size: 20px !important
}

p {
  font-family: Helvetica;
  font-size: 16px !important;
  word-spacing: normal;
  margin-bottom: 0.2rem !important;
}

textarea {
  overflow: auto
}

ol,
ul {
  padding-left: 0px;
  font-family: Helvetica;
  font-size: 16px !important;
  word-spacing: normal;
  margin-bottom: 0.2rem !important;
  text-align: justify;
}

input,
textarea,
select,
button {
  outline: none;
  font-family: Helvetica, sans-serif
}

a {
  text-decoration: none !important;
  transition: all .3s ease-in-out;
  color: #474747;
}



.table th,
.table td {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
}














/* main content style */

.main {
  background-color: #fff;
  min-height: 100vh;
  padding-bottom: 10px;
  position: relative;
  z-index: 1;
}

/* main quick links on every page */

#main_quicklinks .floating_quick_links {
  position: fixed;
  left: -9rem;
  top: 33rem;
  z-index: 2;
}

#main_quicklinks .floating_quick_links .quick_links_menu {
  position: absolute;
  left: -6.7rem;
  top: -18rem;
  width: 14rem;
  transition: all 0.8s ease-in-out;
  z-index: 2;
  font-weight: 400;
}

#main_quicklinks .floating_quick_links:hover .quick_links_menu {
  left: 8.8rem;
}

#main_quicklinks .floating_quick_links .quick_links_btn {
  position: absolute;
  width: 8rem;
  text-align: center;
  left: 6.2rem;
  z-index: 1;
  font-size: 1.2em;
  font-weight: 450;
  padding: 7px 15px 7px 15px;
  background-color: #007bff;
  color: #ffff;
  transform: rotate(90deg);
}

#main_quicklinks .floating_quick_links .quick_links_menu ul {
  background-color: #ffff;
  border-radius: 4px;
  padding: 10px;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
}

#main_quicklinks .floating_quick_links .quick_links_menu ul li {
  padding: 8px 10px 1px 12px;
}

#main_quicklinks .floating_quick_links .quick_links_menu ul a {
  transition: all .3s ease-in-out;
}

#main_quicklinks .floating_quick_links .quick_links_menu ul a:hover {
  color: #d2b356;
  margin-left: 15px;
  text-decoration: none;
}



/* back to top button */

#to-top-btn {
  display: inline-block;
  background-color: #d2b356;
  width: 45px;
  height: 45px;
  border-radius: 25px;
  text-align: center;
  position: fixed;
  bottom: 30px;
  right: 30px;
  /* transition: all .3s; */
  opacity: 0;
  visibility: hidden;
  z-index: 1000;
  border: 2px solid #fff;
  transition: all .3s ease;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.3);
}

#to-top-btn::after {
  content: "\f077";
  font-family: FontAwesome;
  font-weight: normal;
  font-style: normal;
  font-size: 1em;
  line-height: 40px;
  color: #fff;
}

#to-top-btn:hover {
  cursor: pointer;
  background-color: #007bff;
  border-color: #fff;
  transition: all .3s ease;
  background-position: 0 -121px;
}

#to-top-btn:hover::after {
  color: white;

}

#to-top-btn:active {
  background-color: #fff;
}

#to-top-btn.show {
  opacity: 1;
  visibility: visible;
}



.btn:hover,
.btn:focus,
.btn.active {
  color: #fff;
  background-color: #fff;
  color: #d2b356;
  border: 1px solid #d2b356;
}

.btn {
  background: #598abf;
  color: #fff;
  z-index: 9;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  padding: 12px 12px;
  position: relative;
  width: 150px;
}

.btn1 {
  border: 1px solid #fff;
  background: transparent;
  width: 150px;
}

.btn1:hover {
  background: #fff;
  color: #d2b356;
  border: 1px solid #fff;
}


/* 2. header top bar */



/*********** 3. home page **********/

.main-wrapper {
  position: relative;
}

.what-we-do {
  background-color: #f1f6ff;
}


.list-item-containter {
  background-color: #eee3;
  font-family: Arial, Helvetica, sans-serif;
  width: 100%;
  margin: 10px auto;
  padding: 0.5em;
  border-right: 5px solid #EBD671;
  position: relative;
  counter-increment: number;
}


.list-item-containter::before {
  content: counter(number);
  background-color: #EBD671;
  border-left: 1px solid #EBD671;
  border-radius: 5px;
  padding: 10px;
  color: white;
  position: absolute;
  left: -15px;
  top: 0px;
}

.list-item-containter>* {
  margin-left: 2em;
}























/*********** Programmes **********/

.home-container {
  position: relative;
  width: 80%;
  margin: -120px auto 2.8em auto;
  background-color: #fff;
  overflow: hidden;
  border-radius: 10px;
  box-shadow: 1px 20px 30px rgb(42 68 90 / 30%);
  z-index: 1;
}

.home-container .home-about {
  padding-top: 40px;
  padding-bottom: 40px;
  padding-left: 50px;
  padding-right: 10px;
}

.home-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;

}



/* Examimantions */
.container_executive {
  position: relative;
  width: 100%;
  padding: 30px;
  background-color: #f1f1f1;
}

.examinations-container {
  position: relative;
  width: 85%;
  margin: -0px auto 0px auto;
  margin-top: 20px;
  background-color: #fff;
  overflow: hidden;
  border-radius: 10px;
  padding: 0.5em 0.5em;
  box-shadow: 1px 20px 30px rgb(42 68 90 / 30%);
  z-index: 1;
}


.examinations-container .nav-tabs .nav-item .nav-link {
  position: relative;
  color: #000000;
  font-size: 16px;
  font-weight: 600;
  top: 0.5em;
  border: none;
  padding: 18px 0em;
  margin-bottom: 0.2em;
}

.examinations-container .nav-tabs>li {
  margin-left: 10px;
  margin-right: 10px;

}

.examinations-container .nav-tabs .nav-item .nav-link::after {
  content: '';
  display: block;
  height: 2px;
  background-color: #598abf;
  transform: scaleX(0);
  transition: transform ease .3s;
  position: absolute;
  width: 100%;
  left: 0px;
  bottom: 5px;

}

.examinations-container .nav-tabs .nav-item .nav-link:hover::after,
.examinations-container .nav-tabs .nav-item .active::after {
  transform: scaleX(1);
}

.rounded-lg {
  border-radius: 1rem !important;
}

.text-small {
  font-size: 0.9rem !important;
}

.custom-separator {
  width: 4rem;
  height: 2px;
  border-radius: 1rem;
  background: #598abf;
}

.text-uppercase {
  letter-spacing: 0.2em;
}

/* 320px — 480px: Mobile devices */
@media screen and (min-width: 320px) and (max-width: 480px) {

  ol,
  ul {
    padding-left: 0.23rem;
  }

  .main-wrapper {
    margin: 0.5em 0;
  }

  .home-container {
    width: 95%;
  }

  .examinations-container {
    position: relative;
    width: 95%;
    margin: -50px auto 10px auto;
    padding: 1em;

  }



  .list-item-containter {
    padding: 0.5em;

  }


  .list-item-containter::before {
    left: -6px;
    padding: 5px;

  }

  .list-item-containter>* {
    margin-left: 1em;
  }


  .home-container .home-about {
    padding: 0.8em;
  }

  h1,
  h2,
  h3 {
    font-size: 17px !important;
    line-height: 20px;
    padding-bottom: 2px
  }

  p {
    font-size: 15px !important
  }

}

/* 481px — 768px: iPads, Tablets. */
@media screen and (min-width: 481px) and (max-width: 768px) {}




.main_heading {
  position: relative;
  margin-bottom: 65px;
}

.main_heading::after {
  content: "";
  display: block;
  margin: 20px auto 0;
  width: 80px;
  height: 5px;
  background: #598abf;
}

/* Section Title Style Start */

.sec-padding {
  padding: 80px 0;
}

.sec-title {
  margin-bottom: 20px;
}

.sec-title h2 {
  font-size: 32px;
  font-weight: bold;
  margin-top: 0px;
  margin-bottom: 5px;
}

.sec-title h3 {
  font-size: 30px;
  font-weight: bold;
  margin-top: 30px;
  margin-bottom: 15px;
}

.sec-title p {
  font-size: 16px;
  color: var(--thm-secondary-text);
  margin: 0;
  margin-bottom: 20px;
}

.sec-title span.decor {
  display: inline-block;
  position: relative;
  z-index: 1;
}

.sec-title span.inner {
  width: 14px;
  height: 14px;
  background: #598abf;
  display: block;
  margin: auto;
  margin-top: -1px;
  -webkit-transform: rotate(45deg);
}

.sec-title span.decor:before,
.sec-title span.decor:after {
  content: '';
  position: absolute;
  top: 5px;
  width: 55px;
  height: 2px;
  background: #d2d2d2;
  z-index: -1;
}

.sec-title span.decor:before {
  left: 22px;
}

.sec-title span.decor:after {
  right: 22px;
}

.sec-title.text-left span.decor {
  margin-left: 50px;
}

.sec-title.style-two span.decor {
  border-color: #598abf;
}

.sec-title.style-two span.decor .inner {
  background: #598abf;
}

.sec-title.style-two span.decor:after {
  right: -94px;
}

.sec-title.style-two span.decor:before,
.sec-title.style-two span.decor:after {
  background: #DCDCDC;
}

.sec-title.colored span.decor {
  border-color: #2562B3;
}

.sec-title.colored span.decor span.inner {
  background: #2562B3;
}

.sec-title.colored span.decor:before,
.sec-title.colored span.decor:after {
  background: #2562B3;
}


/* Section Title Style End */

.main_heading.text_align_left::after {
  margin-left: 0;
}

.main_heading.text_align_right::after {
  margin-right: 0;
}

examinations-container .nav-tabs .nav-item .nav-link {
  border: none;
  color: #000;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: .2em;
  padding: 18px 0;
  position: relative;
  top: .5em;
}

.font-awesome,
.breadcrumb li+li::before,
.list-style-2 li:before,
.list-style-3 li:before,
.recent-comments-list li:before,
.list-tags li a:before,
.list-group.categories .list-group-item:before {
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
}