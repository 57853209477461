.registration-hero {
    margin-top: 3rem;
    background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url('../../images/clock.jpg');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    height: 28rem;
    padding: 12em 0;
    text-align: center;
    color: #ffffff;
}

.registration-hero h1 {
    font-size: 2.5em;
    font-weight: 600;
}

.registration_download {
    padding: 2px;
    margin-top: 10px;
}

.registration_download .guide-row {
    margin-bottom: 3rem;
}

.registration_download .registration-summary .registration-summary-brief {
    color: #000;
    margin-bottom: 15px !important;
    text-align: justify;
}

.registration_download .guide-row .register-btn {
    outline: 0;
}

.registration_download .img-container {
    width: 15%;
}

.registration_download .img-container img {
    width: 100%;
}

.registration_download .guide {
    display: flex;
    margin-right: -74px;
}

.registration_download .guide-container {
    margin-left: 8px;
}

.registration_download .guide-container .guide-link {
    margin-bottom: 10px;
}

.registration_download .guide-container .guide-link a {
    font-size: 15px;
    color: #598abf;
    font-weight: 700;
    border-bottom: 1px solid #d2b356;
}

.registration_download .guide-container .guide-info p {
    color: #000;
}

.registration_download .guide-row .guide-desc {
    margin-left: -15px;
    padding-left: 10px;
    border-left: 5px solid #d2b356;
    color: #000;
    text-align: justify;
}



/* 320px — 480px: Mobile devices */
@media screen and (min-width: 320px) and (max-width: 480px) {
    .registration-hero {
        margin-top: 1.2rem;
        height: 40vh;
        padding: 6.5em 0;
    }
}

/* 481px — 768px: iPads, Tablets. */
@media screen and (min-width: 481px) and (max-width: 768px) {}