/* Main Container Styles Starts */

.results .main-container {
    margin-top: 60px;
    margin-bottom: 60px;
}

.results .main-container.no-margin-top {
    margin-top: 0;
}


/* Intro Section Styles Starts */

.results .intro {
    margin-top: 60px;
}

.results .intro-box {
    margin-top: 30px;
    margin-bottom: 0;
}

.results .intro-box li {
    margin-top: 25px;
}

.results .intro-box-item {
    position: relative;
}

.results .intro-box-item h4 {
    margin-top: 0;
    margin-bottom: 0;
    padding-top: 10px;
    padding-bottom: 10px;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    color: #fff;
    background: rgba(0, 155, 219, 0.6);
}

/* Box Styles Starts */

.results .box1 {
    margin-top: 20px;
    padding: 10px;
    color: #8d8d8d;
    border: 1px solid #ebebeb;
}

.results .box1 .inner {
    padding: 10px 10px 15px;
}

.results .box1 h4 {
    margin-top: 18px;
    margin-bottom: 15px;
}

.results .box1 h4,
.box1 h4 a {
    color: #323232;
    font-weight: bold;
}

.results .box1 h4 a:hover,
.box1 h4 a:focus {
    color: blue;
}

.results .box1 .btn-1 {
    margin-top: 10px;
    padding-left: 15px;
    padding-right: 15px;
    font-weight: 500;
}

.results .box1 .btn-1:hover,
.box1 .btn-1:focus {
    background-color: #323232;
}

.results .box2 {
    padding: 30px;
    border: 1px solid #cdcdcd;
}

.results .form-box {
    margin-top: 30px;
}

.results .form-box .form-group {
    margin-bottom: 20px;
}

.results .form-box label {
    margin-bottom: 5px;
    color: #3c3c3c;
    font-weight: normal;
    font-size: 15px;
}

.results .form-box input.form-control {
    padding: 7px 14px;
    line-height: normal;
    height: 40px;
    border-color: #d4d6d7;
}

.results .form-box textarea.form-control {
    padding: 15px;
}

.results .form-box .checkbox input[type=checkbox] {
    margin-top: 6px;
}

.results .form-box .checkbox em {
    margin-left: 5px;
}

.results .main-container {
    margin-top: 60px;
    margin-bottom: 60px;
    background-color: #fff;
    height: 100%;
}