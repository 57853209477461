/* Banner Section */

.inner_banner_section {
    background: url('../images/banner/banner.png');
    min-height: 23rem;
    background-size: cover;
    background-position: center center;
}

.inner_banner_section .title-holder {
    margin: 85px 0 0;
}

.inner_banner_section .page-title {
    color: #fff;
    font-size: 40px;
    font-weight: 700;
    line-height: 45px;
    margin-bottom: 15px;
}

.inner_banner_section .breadcrumb {
    background: transparent;
    padding: 0;
    margin: 0;
    color: #fff;
}

.inner_banner_section .breadcrumb li {
    font-weight: 500;
    font-size: 15px;
}

.inner_banner_section .breadcrumb>li+li::before {
    position: relative;
    content: '\203A';
    margin: 0 10px;
    font-size: 18px;
    color: inherit;
    opacity: .7;
    display: inline-block;
}

.inner_banner_section .breadcrumb li a {
    position: relative;
    top: 1px;
    left: 0;
}

.inner_banner_section .breadcrumb li a {
    color: #598abf;
}

/* Conduct Section */

.conduct_layout {
    background-color: #fff !important;
    padding: 1.5em 0;
}

.image_contain {
    height: 100%;
    /* Adjust height as needed */
}

.conduct-img {
    width: 100%;
    --half-height: calc(50%);
}

.conduct_layout .conduct-img :nth-child(1) {
    margin-top: 8em;
}

.conduct_layout .conduct-img :nth-child(2) {
    margin-top: -30em;
    margin-left: 5em;
}

/* eService Section */

.eservice {
    padding: 5px 0px;
    background-color: #f1f1f1;
}

.eservice .main_heading {
    position: relative;
    margin-bottom: 50px;
}

.eservice .main_heading::after {
    content: "";
    display: block;
    margin: 20px auto 0;
    width: 80px;
    height: 5px;
    background: #598abf;
}

.eservice .main_heading.text_align_left::after {
    margin-left: 0;
}

.eservice .main_heading.text_align_right::after {
    margin-right: 0;
}

/* Image Style */

.img,
.block-img,
.user-img {
    width: 100%;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

.service_p {
    font-size: 0.8em !important;
    margin-left: 15px;
}

/* eservice bundle block */
.eservice .block-7 {
    display: flex;
    flex-direction: column;
    height: 23rem;
    border-radius: 4px;
    margin-bottom: 20px;
    padding: 1em;
    overflow: hidden;
    background: #fff;
    -webkit-box-shadow: 0px 24px 48px -13px rgba(0, 0, 0, 0.05);
    -moz-box-shadow: 0px 24px 48px -13px rgba(0, 0, 0, 0.05);
    box-shadow: 0px 24px 48px -13px rgba(0, 0, 0, 0.05);
    -moz-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    -webkit-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    transition: all 0.3s ease;
}


.eservice .block-7 {
    flex: 1;
    /* Allows the content to grow and fill available space */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.eservice .block-7 {
    flex: 1;
}

.eservice .block-7 .p-1 .btn {
    align-self: center;
    margin-bottom: 20px;
}

.eservice .block-7 {
    box-shadow: 1px 20px 30px rgba(42, 68, 90, .5);
    padding-top: 1em;
}

.eservice .block-7 .btn-primary {
    color: #fff;
    text-transform: uppercase;
    font-style: 16px;
    font-weight: 600;
    letter-spacing: 1px;
    width: 60%;
    margin: 0 auto;
}

.eservice .block-7 .price .number {
    font-size: 15px;
    font-weight: 600;
    color: #000000;
    text-align: right;
}

.eservice .block-7 .except {
    color: #598abf;
    font-size: 16px;
    font-weight: 600;
    text-transform: uppercase;
    margin-bottom: 10px;
    margin-top: 10px;
}

.eservice .block-7 .pricing-text li:nth-child(odd) {
    background: #fff;
    height: auto;
}

.eservice .block-7 .pricing-text li {
    list-style: none;
    padding-top: 5px;
    padding-bottom: 2px;
    color: #000000;
}

.eservice .block-7 .img {
    height: 100px;
}

.eservice .block-7 .pricing-text li span.fa {
    color: #207dff;
}

.eservice .pricing-text {
    text-align: left;
    height: auto;
}

.eservice .mr-2,
.mx-2 {
    margin-right: .3rem !important;
}


/*********** Services **********/
.services-hero {
    padding-top: 1.5em;
}


.services-hero .service-hero_right video {
    margin: 2em 7em;
}

.services-hero .service-hero_right .service-image {
    width: 40rem;
    border-radius: 10px;
}

.services {
    padding: 2em 2em;
    background-color: #f1f6ff;
}

.services .box {
    background: #ffffff none repeat scroll 0 0;
    overflow: hidden;
    transition: .3s ease;
    font-size: 1.5em;
    border: 1px dashed #d2b356;
    ;

}

.services .box i {
    color: #d2b356;
    transition: inherit;
    transition-delay: 0s
}

.services h4 {

    transition: inherit;
    transition-delay: .05s;
    font-size: 15px;
}

.services p {
    color: #fff;
    transition: inherit;
    transition-delay: .1s;
}

.services .readmore {

    overflow: hidden;
    display: inline-block;
    font-size: .6rem;
    text-transform: uppercase;
    font-weight: 500;

    position: relative;
    padding-right: 2rem;
    width: 100%;
}

.services .readmore:before {
    animation: ts-link-arrow-effect-animation-out;
    animation-fill-mode: forwards;
    animation-duration: .4s;
    animation-timing-function: cubic-bezier(.6, .01, 0, 1);
    background-color: #d2b356;
    bottom: 0;
    height: .125rem;
    margin: auto;
    left: 0;
    content: '';
    position: absolute;
    top: 0;
    width: 100%;
    transform: scaleX(.2);
    transform-origin: left center;
    z-index: 0;
}

.services .readmore span {
    color: #000;
    position: relative;
    transform: translateX(-200%);
    display: inline-block;
    transition: .6s cubic-bezier(.6, .01, 0, 1);
}

.services ul {
    list-style-type: none;
    position: relative;
    z-index: 0;

}

.services ul li {
    display: block;
    font-size: 14px;

}

.services li:before {
    content: '✓';
    position: absolute;
    left: 0;
    background: #eef;
    padding: 0 0.2em;
    color: green;
    font-weight: bold;
}


.services li {
    text-shadow: 0 0 0px transparent;
    overflow: hidden;
    margin-left: 1.8em;
}

.services .readmore span ul {
    margin-bottom: 0.1rem !;

}

.services .readmore span ul li {
    font-size: 10px !important;
}

.services .box:hover .readmore span {
    transform: translateX(0%);
}

.services .box:hover .readmore:before {
    animation: ts-link-arrow-effect-animation-in;
    animation-fill-mode: forwards;
    animation-duration: .4s;
    animation-timing-function: cubic-bezier(.6, .01, 0, 1);
}

.services .box:hover i,
.services.box:hover h4,
.services.box:hover p {
    transform: translateY(-0.625rem);
}

/* icons */

.fa-circle {
    color: #598abf;
    font-size: 13px;
}

.fa-check {
    color: #598abf;
    font-size: 13px;
}

@keyframes ts-link-arrow-effect-animation-out {
    0% {
        transform-origin: right center;
        transform: scaleX(.2);
    }

    70% {
        transform-origin: right center;
    }

    71% {
        transform-origin: left center;
        transform: scaleX(1);
    }

    100% {
        transform-origin: left center;
        transform: scaleX(.2);
    }
}

@keyframes ts-link-arrow-effect-animation-in {
    0% {
        transform-origin: left center;
        transform: scaleX(.2);
    }

    70% {
        transform-origin: left center;
        transform: scaleX(1);
    }

    71% {
        transform-origin: right center;
    }

    100% {
        transform-origin: right center;
        transform: scaleX(.2);
    }
}

/* ----------- iPhone 4 and 4S ----------- */

/* Portrait and Landscape */
@media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (-webkit-min-device-pixel-ratio: 2) {

    .conduct_layout .conduct-img img {
        min-height: 12em;
        min-width: 12em;
    }

    .conduct_layout .conduct-img :nth-child(1) {
        margin-top: 35px;
    }

    .conduct_layout .conduct-img :nth-child(2) {
        margin-top: -28em;
        margin-left: 5em;
    }

}

/* Portrait */
@media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {}

/* Landscape */
@media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) {}

/* ----------- iPhone 5, 5S, 5C and 5SE ----------- */

/* Portrait and Landscape */
@media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (-webkit-min-device-pixel-ratio: 2) {}

/* Portrait */
@media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {}

/* Landscape */
@media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) {}

/* ----------- iPhone 6, 6S, 7 and 8 ----------- */

/* Portrait and Landscape */
@media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (-webkit-min-device-pixel-ratio: 2) {}

/* Portrait */
@media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {}

/* Landscape */
@media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) {}

/* ----------- iPhone 6+, 7+ and 8+ ----------- */

/* Portrait and Landscape */
@media only screen and (min-device-width: 414px) and (max-device-width: 736px) and (-webkit-min-device-pixel-ratio: 3) {
    .conduct_layout .conduct-img img {
        margin-top: 10px;
        margin-left: 105px;

    }

}

/* Portrait */
@media only screen and (min-device-width: 414px) and (max-device-width: 736px) {
    .conduct_layout .conduct-img img {
        margin-top: 10px;
        margin-left: 105px;

    }

}

/* Landscape */
@media only screen and (min-device-width: 414px) and (max-device-width: 736px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: landscape) {}

/* ----------- iPhone X ----------- */

/* Portrait and Landscape */
@media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 3) {}

/* Portrait */
@media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: portrait) {}

/* Landscape */
@media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: landscape) {}

/* ----------- Galaxy S3 ----------- */

/* Portrait and Landscape */
@media screen and (device-width: 360px) and (device-height: 640px) and (-webkit-device-pixel-ratio: 2) {
    .conduct_layout .conduct-img img {
        min-height: 12em;
        min-width: 12em;
    }

    .conduct_layout .conduct-img :nth-child(1) {
        margin-top: 35px;
    }

    .conduct_layout .conduct-img :nth-child(2) {
        margin-top: -12em;
        margin-left: 4em;
    }
}

/* ----------- iPad 1, 2, Mini and Air ----------- */

/* Portrait and Landscape */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) {
    .conduct_layout .conduct-img img {
        min-height: 10em;
        min-width: 10em;
    }

    .conduct_layout .conduct-img :nth-child(1) {
        margin-top: 35px;
    }

    .conduct_layout .conduct-img :nth-child(2) {
        margin-top: -12em;
        margin-left: 4em;
    }
}

/* ----------- iPad Pro 12.9" ----------- */
/* Landscape */
@media only screen and (min-device-width: 1366px) and (max-device-width: 1366px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
    .conduct_layout .conduct-img img {
        min-height: 240px;
        min-width: 240px;
    }

    @media only screen and (min-width: 1366px) and (max-width: 1366px) and (min-height: 768px) and (max-height: 768px) {
        /* CSS rules for 1366x768 resolution */
    }
}

@media (min-width: 380px) {}

@media (min-width: 380px) and (max-width: 577px) {

    /* Styles for viewports 768px and wider */
    .conduct_layout .conduct-img :nth-child(1) {
        margin-top: 10em;
    }

    .conduct_layout .conduct-img :nth-child(2) {
        margin-top: -35em;
        margin-left: 5em;
    }
}

@media (min-width: 578px) {
    .conduct-img {
        width: 70%;
        --half-height: calc(50%);
    }

    /* Styles for viewports 768px and wider */
    .conduct_layout .conduct-img :nth-child(1) {
        margin-top: 8em;
    }

    .conduct_layout .conduct-img :nth-child(2) {
        margin-top: -30em;
        margin-left: 5em;
    }
}

@media (min-width: 768px) {
    .conduct-img {
        width: 90%;
        --half-height: calc(50%);
    }

    /* Styles for viewports 768px and wider */
    .conduct_layout .conduct-img :nth-child(1) {
        margin-top: 10em;
    }

    .conduct_layout .conduct-img :nth-child(2) {
        margin-top: -30em;
        margin-left: 4em;
    }
}


@media (min-width: 992px) {

    /* Styles for viewports 768px and wider */
    .conduct_layout .conduct-img :nth-child(1) {
        margin-top: 10em;
    }

    .conduct_layout .conduct-img :nth-child(2) {
        margin-top: -35em;
        margin-left: 5em;
    }
}